import { useState } from "react";

export const useRangeDatePicker = (initialStartDate?: string, initialEndDate?: string) => {
  const [startDate, setStartDate] = useState<string | undefined>(initialStartDate);
  const [endDate, setEndDate] = useState<string | undefined>(initialEndDate);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate">("startDate");

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    anchorEl,
    setAnchorEl,
    openPopover,
    setOpenPopover,
    focusedInput,
    setFocusedInput,
  };
};
