import { ButtonGroup } from "@new-black/lyra";

import WarningIcon from "components/shared/warning-icon";

import { useProductFilterContext } from "../product-filter-context";

import ProductFilterAddProductsAction from "./product-filter-add-products-action";
import ProductOverviewAction from "./product-overview-action";

/**
 * Component can be used to render the default actions for the product filter table
 */
const DefaultProductFilterActions = () => {
  const { disableAdd, error } = useProductFilterContext();

  return (
    <div className="flex items-center gap-2">
      {error ? <WarningIcon color="error" tooltipMessage={error} /> : null}

      <ButtonGroup>
        <ProductOverviewAction />

        {disableAdd ? null : <ProductFilterAddProductsAction />}
      </ButtonGroup>
    </div>
  );
};

export default DefaultProductFilterActions;
