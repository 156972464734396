/* eslint-disable import/no-cycle */

import { ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup } from "@new-black/lyra";
import { Field, FieldProps } from "formik";

import LoadingStateBox from "components/shared/loading-state-box";
import ErrorBoundary from "components/suite-ui/error-boundary";
import Input from "components/suite-ui/input";
import Text from "components/suite-ui/text";
import { Checkbox } from "components/ui/checkbox";
import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";
import { CustomFieldDataType } from "types/custom-field-data-types";

import { EntityTranslationField } from "../entity-translation-field";
import { EVAFormik } from "../eva-formik";
import { FormikInput } from "../formik-inputs";

import BackendIDField from "./field-groups/backend-id-field";
import DataTypeSelector from "./field-groups/data-type-selector";
import EnumOptions from "./field-groups/enum-options";
import InstructionField from "./field-groups/instruction-field";
import CreateOrUpdateCustomFieldAction from "./create-update-custom-field-action";
import CustomFieldChanged from "./custom-field-changed";
import { CustomFieldSettings } from "./custom-field-settings";
import { DeleteCustomFieldAction } from "./delete-custom-field-action";
import { TCustomFieldAllowedArrayDataTypes } from "./single-custom-field.types";
import {
  CUSTOM_FIELD_DISPLAY_NAME_ENTITY_FIELD,
  CUSTOM_FIELD_TRANSLATION_ENTITY_TYPE,
  ICustomFieldComponent,
  ICustomFieldValues,
} from "./types";
import useCustomFieldByID from "./use-custom-field-by-id";
import useCustomFieldValidation from "./use-custom-field-validation";
import useHandleCustomField from "./use-handle-custom-field";

export interface ISingleCustomFieldProps extends ICustomFieldComponent {
  successMessageOnCreate?: string;
  onDelete?: () => void;
  onCancel?: () => void;
  passive?: boolean;
  hideDeleteAction?: boolean;
  customHeader?: string;
  footerDivider?: ReactNode;
  /** Shows an usual input instead of `EntityTranslationField` component */
  disableEntityTranslations?: boolean;
  messages?: {
    createButtonMessage?: string;
    updateButtonMessage?: string;
  };
}

const SingleCustomField = ({
  customHeader,
  defaultValues,
  disableEntityTranslations,
  footerDivider,
  hideDeleteAction,
  ID,
  messages,
  onCancel,
  onDelete,
  onSuccessCallback,
  passive,
  showOptionsTable = false,
  successMessageOnCreate,
}: ISingleCustomFieldProps) => {
  const intl = useIntl();

  const {
    isLoadingWithoutPreviousResponse,
    refetchCustomFieldByID,
    values: customFieldByIDValues,
  } = useCustomFieldByID(ID);

  useHandleSWRFamily(useCustomFieldByID.service.serviceState, ID);

  const initialValues = useMemo<ICustomFieldValues>(
    () => ({
      DataType: customFieldByIDValues?.DataType ?? defaultValues.DataType,
      Name: customFieldByIDValues?.Name ?? defaultValues.Name,
      ID: customFieldByIDValues?.ID ?? defaultValues.ID,
      TypeID: customFieldByIDValues?.TypeID ?? defaultValues.TypeID,
      DisplayName: customFieldByIDValues?.DisplayName ?? defaultValues.DisplayName,
      BackendID: customFieldByIDValues?.BackendID ?? defaultValues.BackendID,
      TypeKey: customFieldByIDValues?.TypeKey ?? defaultValues.TypeKey,
      Order: customFieldByIDValues?.Order ?? defaultValues.Order,
      EnumValues: customFieldByIDValues?.EnumValues ?? defaultValues.EnumValues,
      IsArray: customFieldByIDValues?.IsArray ?? defaultValues.IsArray,
    }),
    [customFieldByIDValues, defaultValues],
  );

  const validationSchema = useCustomFieldValidation();
  const handleCustomField = useHandleCustomField(
    ID,
    onSuccessCallback,
    successMessageOnCreate,
    refetchCustomFieldByID,
  );

  return isLoadingWithoutPreviousResponse ? (
    <LoadingStateBox limit={6} />
  ) : (
    <EVAFormik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        handleCustomField(values, customFieldByIDValues?.DataType, formikHelpers);
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <div className="flex flex-col gap-5">
          <Text variant="h3">
            {customHeader ?? (
              <FormattedMessage id="generic.label.general-info" defaultMessage="General info" />
            )}
          </Text>

          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 md:flex-row">
              <InstructionField passive={passive} />

              <BackendIDField passive={passive} />
            </div>

            <div className="flex flex-col gap-5 md:flex-row">
              <DataTypeSelector passive={passive} />

              <Field name="DisplayName">
                {({ field }: FieldProps<string | undefined>) =>
                  disableEntityTranslations ? (
                    <FormikInput
                      {...field}
                      label={intl.formatMessage({
                        id: "custom-field.input.display-name",
                        defaultMessage: "Display name",
                      })}
                    />
                  ) : (
                    <EntityTranslationField
                      label={intl.formatMessage({
                        id: "custom-field.input.display-name",
                        defaultMessage: "Display name",
                      })}
                      passive={passive}
                      {...field}
                      value={field.value ?? ""}
                      entity={{
                        entityID: `${values.ID}`,
                        entityType: CUSTOM_FIELD_TRANSLATION_ENTITY_TYPE,
                        entityField: CUSTOM_FIELD_DISPLAY_NAME_ENTITY_FIELD,
                      }}
                      disableEntityTranslations={!values.ID}
                    />
                  )
                }
              </Field>
            </div>

            {(values.DataType as unknown as CustomFieldDataType) === CustomFieldDataType.Enum ? (
              <EnumOptions multiple={values.IsArray} passive={passive} />
            ) : (
              <>
                {Object.values(TCustomFieldAllowedArrayDataTypes).includes(
                  values.DataType as number,
                ) ? (
                  !ID ? (
                    <Checkbox
                      name="IsArray"
                      className="ml-3"
                      label={intl.formatMessage({
                        id: "generic.label.is-array",
                        defaultMessage: "Is array",
                      })}
                      value={values.IsArray}
                      onChange={(newValue) => {
                        setFieldValue("IsArray", newValue);
                      }}
                    />
                  ) : (
                    <Input
                      name="IsArray"
                      passive
                      label={intl.formatMessage({
                        id: "generic.label.is-array",
                        defaultMessage: "Is array",
                      })}
                      value={intl.formatMessage(
                        values?.IsArray
                          ? { id: "generic.label.true", defaultMessage: "True" }
                          : { id: "generic.label.false", defaultMessage: "False" },
                      )}
                    />
                  )
                ) : null}
              </>
            )}
          </div>

          {showOptionsTable && ID ? (
            <ErrorBoundary>
              <CustomFieldSettings customFieldId={ID} variant="secondary" />
            </ErrorBoundary>
          ) : null}

          {footerDivider}

          <div className="flex items-center justify-between">
            <div>
              {!hideDeleteAction && ID ? (
                <DeleteCustomFieldAction ID={ID} onSuccess={onDelete} />
              ) : null}
            </div>

            <ButtonGroup>
              {onCancel ? (
                <Button variant="secondary" onPress={onCancel} isDisabled={isSubmitting}>
                  <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                </Button>
              ) : null}

              <CreateOrUpdateCustomFieldAction ID={ID} messages={messages} />
            </ButtonGroup>
          </div>

          <CustomFieldChanged ID={ID} />
        </div>
      )}
    </EVAFormik>
  );
};

export default SingleCustomField;
