import { useMemo } from "react";

import { round } from "lodash";

import Text from "../text";

import { ColoredMuiCircularProgress } from "./helpers";
import { CircularProgressColors } from "./types";

export { CircularProgressColors };

export interface ICircularProgressProps {
  /**
   * A number between 0 and 100 (inclusive on both ends).
   *
   * - Every value below 0 will reset back to 0.
   * - Every value above 100 will reset back to 100.
   * - Every value is rounded to a precision of 0 (*for now*).
   */
  progress: number;
  /**
   * `CircularProgressColors` enum value or any other color that can be passed to the CSS `color` field
   */
  color?: CircularProgressColors | React.CSSProperties["color"];
}

const CircularProgress = ({ color: customColor, progress }: ICircularProgressProps) => {
  const progressValue = useMemo(() => {
    if (progress <= 0) {
      return 0;
    }
    if (progress >= 100) {
      return 100;
    }
    return round(progress, 0);
  }, [progress]);

  const color = useMemo(() => {
    if (customColor) {
      return customColor;
    }
    if (progressValue === 100) {
      return CircularProgressColors.GREEN;
    }
    return progressValue <= 25 ? CircularProgressColors.RED : CircularProgressColors.ORANGE;
  }, [customColor, progressValue]);

  return (
    <div className="relative inline-flex">
      <ColoredMuiCircularProgress color={color} variant="determinate" value={progressValue} />
      <div className="absolute bottom-0 left-0 right-0 top-0 flex h-[41px] w-[41px] content-center items-center justify-center justify-items-center">
        <Text variant="caption">{`${progressValue}%`}</Text>
      </div>
    </div>
  );
};

export default CircularProgress;
