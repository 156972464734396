import { cva } from "class-variance-authority";

import { ISearchListFieldBaseProps } from "../types";

const labelStyles = (hasValue: boolean) =>
  cva(
    [
      "truncate",
      "origin-top-left transform leading-6 transition-all",
      "select-none",
      "text-[color:#666666]",
    ],
    {
      variants: {
        small: {
          true: [
            "text-[14px] truncate",
            hasValue ? "-translate-y-2.5 scale-75 absolute top-0 left-4 max-w-[125%]" : "scale-100",
          ],
          false: [
            "text-[16px] truncate",
            hasValue ? "-translate-y-2.5 scale-75 absolute top-0 left-4 max-w-[125%]" : "scale-100",
          ],
        },
        error: {
          true: "",
        },
        warning: {
          true: "",
        },
        isPopoverOpen: {
          true: "",
        },
        disabled: {
          true: "",
        },
        required: {
          true: "after:content-['*']",
        },
      },
      compoundVariants: [
        {
          error: true,
          warning: false,
          class: "text-error",
        },
        {
          error: true,
          warning: true,
          class: "text-error",
        },
        {
          error: false,
          warning: true,
          class: "text-[color:var(--color-warning-3)]",
        },
        {
          isPopoverOpen: true,
          error: false,
          warning: false,
          class: hasValue ? "text-[color:var(--color-primary-3)]" : "",
        },
        {
          disabled: false,
          error: false,
          warning: false,
          class: hasValue ? "group-focus-within:text-[color:var(--color-primary-3)]" : "",
        },
      ],
    },
  );

export const SearchListFieldLabel = ({
  disabled,
  error,
  hasValue,
  htmlFor,
  isPopoverOpen,
  label,
  onClick,
  required,
  small,
  warning,
}: Pick<ISearchListFieldBaseProps<any>, "error" | "warning" | "disabled" | "small" | "required"> & {
  hasValue: boolean;
  isPopoverOpen: boolean;
  htmlFor?: string;
  label: string;
  onClick: () => void;
}) => (
  <label
    className={labelStyles(hasValue)({
      error: !!error,
      warning: !!warning,
      disabled: !!disabled,
      required: !!required,
      small: !!small,
      isPopoverOpen,
    })}
    htmlFor={htmlFor}
    onClick={onClick}
  >
    {label}
  </label>
);
