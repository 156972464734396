import { CoreManagement } from "@springtree/eva-services-core-management";

import { listPriceListsQuery } from "models/price-lists";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export type PriceListSearchListFieldItem = Pick<EVA.Core.Management.PriceListDto, "Name" | "ID">;

export const generatePriceListSearchListField = () =>
  SearchListFieldGenerator<CoreManagement.ListPriceLists, PriceListSearchListFieldItem, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listPriceListsQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue,
            },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) => resp?.Result.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.price-list",
      defaultMessage: "Price list",
    }),
  });
