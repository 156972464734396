import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useHasFunctionality } from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { IChapter } from "../chapter-finder.types";

export const usePimChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: productBundlesChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_BUNDLES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: productRelationsChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_RELATION_TYPES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: productSetsChapterAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_SEARCHES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: supplierProductsAllowed } = useHasFunctionality(
    Functionalities.SUPPLIER_PRODUCTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: unitsOfMeasureAllowed } = useHasFunctionality(
    Functionalities.UNIT_OF_MEASURES,
    FunctionalityScope.View,
  );

  const pimChapters = useMemo<IChapter[]>(() => {
    let chapters: IChapter[] = [];

    if (productBundlesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.pim.chapters.product-bundles",
            defaultMessage: "Product bundles",
          }),
          path: "/product-bundles",
        },
      ];
    }
    if (productRelationsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.pim.chapters.product-relations",
            defaultMessage: "Product relations",
          }),
          path: "/product-relations",
        },
      ];
    }
    if (productSetsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.label.product-sets",
            defaultMessage: "Product sets",
          }),
          path: "/product-sets",
        },
      ];
    }

    if (unitsOfMeasureAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.label.units-of-measure",
            defaultMessage: "Units of measure",
          }),
          path: "/units-of-measure",
        },
      ];
    }

    chapters = [
      ...chapters,

      {
        title: `${intl.formatMessage({
          id: "module.pim.chapters.products",
          defaultMessage: "Products",
        })}`,
        path: "/products",
      },

      {
        title: intl.formatMessage({
          id: "module.pim.chapters.product-requirements",
          defaultMessage: "Product requirements",
        }),
        path: "/product-requirements",
      },
    ];

    if (supplierProductsAllowed) {
      chapters = [
        ...chapters,

        // Plugin (Admin 1.0 redirect)
        {
          title: intl.formatMessage({
            id: "module.pim.chapters.plugin",
            defaultMessage: "Supplier product upload",
          }),
          path: "/plugin",
        },
      ];
    }

    return chapters;
  }, [
    intl,
    productBundlesChapterAllowed,
    productRelationsChapterAllowed,
    productSetsChapterAllowed,
    supplierProductsAllowed,
    unitsOfMeasureAllowed,
  ]);

  return pimChapters;
};
