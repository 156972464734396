import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listAssortmentsLoaderQuery,
  serviceQuery: listAssortmentsQuery,
  serviceQueryKeys: listAssortmentsQueryKeys,
  useServiceQueryHook: useListAssortmentsQuery,
} = createServiceQuery(CoreManagement.ListAssortments, true);

export const {
  serviceLoaderQuery: getAssortmentByIDLoaderQuery,
  serviceQuery: getAssortmentByIDQuery,
  serviceQueryKeys: getAssortmentByIDQueryKeys,
  useServiceQueryHook: useGetAssortmentByIDQuery,
} = createServiceQuery(CoreManagement.GetAssortmentByID, true);
