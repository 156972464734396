import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { MaterialAutocomplete } from "components/suite-ui/autocomplete";
import { useGetAvailableCurrenciesQuery } from "models/application";
import { ONE_HOUR } from "util/base-values";

interface CurrencyAutocompleteProps {
  name: string;
  label?: string;
  defaultValue?: string;
  error?: string;
  required?: boolean;
  onChange?: (newValue?: string) => void;
  disabled?: boolean;
}

// TODO: remove this component after all usages are replaced with the new, Lyra-based SLF
export function CurrencyAutocomplete({
  defaultValue,
  disabled,
  error,
  label,
  name,
  onChange,
  required,
}: CurrencyAutocompleteProps) {
  const intl = useIntl();
  const { data: getAvailableCurrenciesResponse } = useGetAvailableCurrenciesQuery(
    {},
    { staleTime: ONE_HOUR },
  );
  const currencies = useMemo(() => {
    const allCurrencies = getAvailableCurrenciesResponse?.Currencies;

    const sortedCurrencies = allCurrencies?.sort((a, b) => {
      return a.Name.localeCompare(b.Name);
    });

    return sortedCurrencies ?? [];
  }, [getAvailableCurrenciesResponse?.Currencies]);

  const [value, setValue] = useState<string | undefined>(defaultValue);

  return (
    <>
      <MaterialAutocomplete
        label={
          label ?? intl.formatMessage({ id: "generic.label.currency", defaultMessage: "Currency" })
        }
        items={currencies}
        IDKey="ID"
        LabelKey="Name"
        required={required}
        value={currencies.find((currency) => currency.ID === value)}
        setValue={(currency) => {
          setValue(currency?.ID);
          onChange?.(currency?.ID);
        }}
        error={!!error}
        helperText={error}
        disabled={disabled}
      />
      <input type="hidden" name={name} value={value ?? ""} />
    </>
  );
}
