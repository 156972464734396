import { useCallback } from "react";
import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";
import { FormikHelpers } from "formik";
import { isNil, omit } from "lodash";
import { useSetRecoilState } from "recoil";

import { convertEnumValuesTypeToKeyObj } from "./helpers";
import { mutateCreateCustomField } from "./mutate-create-custom-field";
import { mutateUpdateCustomField } from "./mutate-update-custom-field";
import { singleCustomFieldValuesSubmitting } from "./state";
import { ICustomFieldValues } from "./types";

interface IHandleCustomFieldParams
  extends Omit<EVA.Core.Management.CreateCustomField, "ID" | "Order"> {
  ID?: number;
  Order: number;
  EnumValues?: { [key: string]: string };
}

const useHandleCustomField = (
  ID: number | undefined,
  onSuccessCallback: ((id: number | undefined, refetchCustomField: () => void) => void) | undefined,
  successMessageOnCreate: string | undefined,
  refetchCustomField: () => void,
) => {
  const intl = useIntl();

  const setCustomFieldIsSubmitting = useSetRecoilState(singleCustomFieldValuesSubmitting(ID));

  const createCustomFieldHandler = mutateCreateCustomField(successMessageOnCreate, true);

  const updateCustomFieldHandler = mutateUpdateCustomField(true);

  const { mutate: updateCustomField } = useMutation(updateCustomFieldHandler, {
    onSuccess: () => {
      onSuccessCallback?.(undefined, refetchCustomField);
    },
    onSettled: () => {
      setCustomFieldIsSubmitting(false);
    },
  });

  const { mutate: createCustomField } = useMutation(createCustomFieldHandler, {
    onSuccess: (result) => {
      onSuccessCallback?.(result?.response?.ID, refetchCustomField);
    },
    onSettled: () => {
      setCustomFieldIsSubmitting(false);
    },
  });

  const transformData = useCallback((params: ICustomFieldValues): IHandleCustomFieldParams => {
    const fieldData = {
      Name: params.Name,
      ID: params?.ID,
      DataType: params.DataType,
      DisplayName: params.DisplayName,
      Order: params?.Order!,
      TypeID: params.TypeID,
      BackendID: params.BackendID?.length ? params.BackendID : (null as any),
      TypeKey: params.TypeKey,
      IsArray: params.IsArray ?? false,
    };
    switch (params.DataType) {
      case 4: {
        const convertedEnumValues = params.EnumValues
          ? convertEnumValuesTypeToKeyObj(params.EnumValues)
          : {};
        return {
          ...fieldData,
          EnumValues: convertedEnumValues,
          IsArray: params.IsArray,
        };
      }
      case 0:
      case 1:
      case 2:
      case 3:
      case 5:
      case 6:
      case 7:
      default:
        return { ...fieldData };
    }
  }, []);

  const handleDuplicateBackendID = useCallback(
    (formikHelpers: FormikHelpers<ICustomFieldValues> | undefined, e: any) => {
      const error = e as EVA.Core.EmptyResponseMessage;

      if (error?.Error?.Type === "CustomFields:DuplicateBackendID") {
        formikHelpers?.setFieldError(
          "BackendID",
          intl.formatMessage({
            id: "generic.label.duplicate-backend-id-message",
            defaultMessage: "The requested BackendID is already in use",
          }),
        );
      }
    },
    [intl],
  );

  const handleCustomFields = useCallback(
    async (
      params: ICustomFieldValues,
      dataTypeInResponse?: EVA.Core.CustomFieldDataTypes,
      formikHelpers?: FormikHelpers<ICustomFieldValues>,
    ) => {
      setCustomFieldIsSubmitting(true);

      if (params.ID && !isNil(dataTypeInResponse)) {
        updateCustomField(
          {
            ...omit(transformData(params), ["TypeID", "IsArray"]),
            ID: params.ID,
          },
          {
            onError: (e) => {
              handleDuplicateBackendID(formikHelpers, e);
            },
            onSuccess: () => {
              formikHelpers?.resetForm();
            },
          },
        );
      } else {
        createCustomField(transformData(params), {
          onError: (e) => {
            handleDuplicateBackendID(formikHelpers, e);
          },
        });
      }
    },
    [
      createCustomField,
      handleDuplicateBackendID,
      setCustomFieldIsSubmitting,
      transformData,
      updateCustomField,
    ],
  );

  return handleCustomFields;
};

export default useHandleCustomField;
