export enum ScriptType {
  DiscountFinancialDispersion = "DiscountFinancialDispersion",
  OrdersMonitor = "OrdersMonitor",
  UserInquiry = "UserInquiry",
  CheckoutOptions = "CheckoutOptions",
  UserRequirementForCreate = "UserRequirementForCreate",
  UserRequirementForOrder = "UserRequirementForOrder",
  PaymentTypeAvailability = "PaymentTypeAvailability",
  ShippingRestriction = "ShippingRestriction",
}

export enum ScriptDialect {
  Extension = "Extension",
}

export enum ScriptSource {
  CustomFieldSecurity = "CustomFieldSecurity",
}
