import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";

import { TableProps } from "@new-black/lyra";

import useConvertEVAFilterToArray, {
  IEVAFilterArrayItem,
} from "../hooks/use-convert-filter-to-array";
import { useProductFilterContext } from "../product-filter-context";

import useListTableColumns from "./use-list-table-columns";

export default function useListTableSettings({
  customRowActions,
  options,
  ...props
}: {
  options?: TableProps<IEVAFilterArrayItem>["options"];
  "aria-label"?: TableProps<IEVAFilterArrayItem>["aria-label"];
  customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode;
}) {
  const intl = useIntl();
  const { productFilter } = useProductFilterContext();
  const filterToArr = useConvertEVAFilterToArray(productFilter);

  const total = useMemo(() => filterToArr?.length ?? 0, [filterToArr]);

  const columns = useListTableColumns({ customRowActions });

  const listConfig = useMemo<TableProps<IEVAFilterArrayItem>>(
    () => ({
      skip: 0,
      columns,
      options,
      data: filterToArr ?? [],
      total: total,
      "aria-label":
        props["aria-label"] ??
        intl.formatMessage({
          id: "generic.label.products",
          defaultMessage: "Products",
        }),
      limit: (filterToArr?.length ?? 0) < 10 ? 10 : filterToArr?.length,
    }),
    [columns, filterToArr, intl, options, props, total],
  );

  return listConfig;
}
