import { useEffect } from "react";

import { useFormikContext } from "formik";
import { useSetRecoilState } from "recoil";

import { singleCustomFieldValuesDirty } from "./state";

interface CustomFieldChangedProps {
  ID?: number;
}

const CustomFieldChanged = ({ ID }: CustomFieldChangedProps) => {
  const { dirty } = useFormikContext();

  const setCustomFieldChanged = useSetRecoilState(singleCustomFieldValuesDirty(ID));

  useEffect(() => {
    setCustomFieldChanged(dirty);
  }, [dirty, setCustomFieldChanged]);

  return null;
};

export default CustomFieldChanged;
