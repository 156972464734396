import { useIntl } from "react-intl";

import { Field, FieldProps } from "formik";

import Input from "components/suite-ui/input";

interface IBackendIDField {
  passive?: boolean;
}

const BackendIDField = ({ passive }: IBackendIDField) => {
  const intl = useIntl();
  return (
    <Field name="BackendID">
      {({ field, meta }: FieldProps) => (
        <Input
          label={intl.formatMessage({
            id: "generic.label.backend-id",
            defaultMessage: "Backend ID",
          })}
          {...field}
          passive={passive}
          value={field.value ?? ""}
          error={!!meta.error && meta.touched}
          helperText={!!meta.error && meta.touched ? meta.error : undefined}
        />
      )}
    </Field>
  );
};

export default BackendIDField;
