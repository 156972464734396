import MuiCircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import classNames from "classnames";

import { CircularProgressColors } from "./types";

export interface IColoredMuiCircularProgressProps extends Omit<CircularProgressProps, "color"> {
  color: CircularProgressColors | React.CSSProperties["color"];
}

export const ColoredMuiCircularProgress = ({
  color,
  ...props
}: IColoredMuiCircularProgressProps) => (
  <div className="relative">
    <MuiCircularProgress
      {...props}
      className="text-[color:var(--legacy-eva-color-transparent-50)]"
      variant="determinate"
      value={100}
    />
    <MuiCircularProgress
      {...props}
      className={classNames("absolute left-0", color && `text-[color:${color}]`)}
      variant="determinate"
    />
  </div>
);
