import { cva } from "class-variance-authority";

export const startPaneStyle = cva("flex flex-col", {
  variants: {
    proportions: {
      "1/3-2/3": "w-1/3",
      "2/3-1/3": "w-2/3",
      "1/2-1/2": "w-1/2",
    },
  },
  defaultVariants: {
    proportions: "1/3-2/3",
  },
});

export const endPaneStyle = cva("flex flex-col", {
  variants: {
    proportions: {
      "1/3-2/3": "w-2/3",
      "2/3-1/3": "w-1/3",
      "1/2-1/2": "w-1/2",
    },
  },
  defaultVariants: {
    proportions: "1/3-2/3",
  },
});
