import { FocusEvent, MouseEvent, useCallback, useState } from "react";

import classNames from "classnames";
import { useField } from "formik";
import moment, { Moment } from "moment";

import DatePickerField from "components/suite-ui/date-picker/date-picker-field";
import DateRangePicker from "components/suite-ui/date-picker/date-range-picker";

interface IFormikDateRangePickerProps<TForm> {
  startHelperText?: string;
  endHelperText?: string;
  endDateLabel: string;
  startDateLabel: string;
  endName: keyof TForm & string;
  startName: keyof TForm & string;
  wrapperClassName?: string;
  startRequired?: boolean;
  endRequired?: boolean;
  onStartChange?: (startValue?: string) => void;
  onEndChange?: (endValue?: string) => void;
}

type DateRangeController = "startDate" | "endDate";

export const FormikDateRangePicker = <TForm,>({
  endDateLabel,
  endHelperText,
  endName,
  endRequired,
  onEndChange,
  onStartChange,
  startDateLabel,
  startHelperText,
  startName,
  startRequired,
  wrapperClassName,
}: IFormikDateRangePickerProps<TForm>) => {
  const [
    { value: startDateValue },
    startMeta,
    { setTouched: setStartTouched, setValue: setStartValue },
  ] = useField<string | undefined>(startName);
  const [{ value: endDateValue }, endMeta, { setTouched: setEndTouched, setValue: setEndValue }] =
    useField<string | undefined>(endName);

  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [focusedInput, setFocusedInput] = useState<DateRangeController>("startDate");

  const handleOpen = useCallback(
    (controller: DateRangeController) => (event: MouseEvent<any> | FocusEvent<any>) => {
      setOpenPopover(true);
      setAnchorEl(event.currentTarget);
      setFocusedInput(controller);
      setStartTouched(true, true);
      setEndTouched(true, true);
    },
    [setEndTouched, setStartTouched],
  );

  const handleDateChange = useCallback(
    ({ endDate, startDate }: { startDate: Moment | null; endDate: Moment | null }) => {
      if (startDate) {
        setStartValue(moment(startDate).format("YYYY-MM-DD"));
        onStartChange?.(moment(startDate).format("YYYY-MM-DD"));
      }
      if (endDate) {
        setEndValue(moment(endDate).format("YYYY-MM-DD"));
        onEndChange?.(moment(endDate).format("YYYY-MM-DD"));
      }
    },
    [onEndChange, onStartChange, setEndValue, setStartValue],
  );

  const handleFocusChange = useCallback((newFocusedInput: DateRangeController | null) => {
    if (newFocusedInput !== null) {
      setFocusedInput(newFocusedInput);
    }
  }, []);

  return (
    <>
      <div className={classNames("flex flex-col gap-4 md:flex-row", wrapperClassName)}>
        <DatePickerField
          fullWidth
          label={startDateLabel}
          onFocus={handleOpen("startDate")}
          handleClick={handleOpen("startDate")}
          clear={() => setStartValue(undefined)}
          error={!!startMeta.error && startMeta.touched}
          onChange={(event) => setStartValue(event.target.value)}
          helperText={startMeta.error && startMeta.touched ? startMeta.error : startHelperText}
          value={startDateValue ? moment(startDateValue).format("YYYY-MM-DD") : ""}
          required={startRequired}
        />

        <DatePickerField
          fullWidth
          label={endDateLabel}
          onFocus={handleOpen("endDate")}
          handleClick={handleOpen("endDate")}
          clear={() => setEndValue(undefined)}
          error={!!endMeta.error && endMeta.touched}
          onChange={(event) => setEndValue(event.target.value)}
          helperText={endMeta.error && endMeta.touched ? endMeta.error : endHelperText}
          value={endDateValue ? moment(endDateValue).format("YYYY-MM-DD") : ""}
          required={endRequired}
        />
      </div>
      <DateRangePicker
        minimumNights={0}
        open={openPopover}
        numberOfMonths={2}
        anchorEl={anchorEl}
        setOpen={setOpenPopover}
        focusedInput={focusedInput}
        onDatesChange={handleDateChange}
        onFocusChange={handleFocusChange}
        id={`dateRange-${startName}-${endName}`}
        endDate={endDateValue ? moment(endDateValue) : null}
        startDate={startDateValue ? moment(startDateValue) : null}
      />
    </>
  );
};
