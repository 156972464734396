import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";

import Tabs, { ITab } from "components/suite-ui/tabs";

import { useProductFilterContext } from "../product-filter-context";

import DialogTabPrefill from "./tab-prefill";
import DialogTabRange from "./tab-range";
import DialogTabValue from "./tab-value";

const ProductFilterModal = () => {
  const intl = useIntl();
  const {
    currentProductFilterModalValues,
    openProductFilterModal,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
  } = useProductFilterContext();

  const tabs: ITab[] = useMemo<ITab[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "product-filter.modal.values.title",
          defaultMessage: "Values",
        }),
        tabPanel: {
          value: 0,
          children: <DialogTabValue />,
        },
      },
      {
        label: intl.formatMessage({
          id: "product-filter.modal.range.title",
          defaultMessage: "Range",
        }),
        tabPanel: {
          value: 1,
          children: <DialogTabRange />,
        },
      },
      {
        label: intl.formatMessage({
          id: "product-filter.modal.prefill.title",
          defaultMessage: "Prefill",
        }),
        tabPanel: {
          value: 2,
          children: <DialogTabPrefill />,
        },
      },
    ],
    [intl],
  );

  const initialActiveTab = useMemo(
    () =>
      !!currentProductFilterModalValues?.From || !!currentProductFilterModalValues?.To ? 1 : 0,
    [currentProductFilterModalValues?.From, currentProductFilterModalValues?.To],
  );

  return (
    <Dialog
      open={openProductFilterModal}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        setOpenProductFilterModal(false);
        setCurrentProductFilterModalValues(undefined);
      }}
    >
      <Tabs tabs={tabs} variant="fullWidth" initialActiveTab={initialActiveTab} />
    </Dialog>
  );
};

export default ProductFilterModal;
