import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { useFormikContext } from "formik";
import { produce } from "immer";
import { v4 as uuidV4 } from "uuid";

import { CheckboxOffIcon, RadioButtonOffIcon } from "assets/icons/components/custom-field-icons";
import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import Input from "components/suite-ui/input";

import { EnumValuesType, ICustomFieldValues } from "../types";

interface IEnumOptions {
  multiple?: boolean;
  passive?: boolean;
}

const EnumOptions = ({ multiple, passive }: IEnumOptions) => {
  const intl = useIntl();
  const { setFieldValue, values } = useFormikContext<ICustomFieldValues>();

  const enums = useMemo(() => values?.EnumValues, [values?.EnumValues]);

  const setEnums = useCallback(
    (enumValues: EnumValuesType) => {
      setFieldValue("EnumValues", enumValues);
    },
    [setFieldValue],
  );

  const enumEntries = useMemo(() => {
    return !passive
      ? Object.entries({
          ...enums,
          [uuidV4()]: { key: "", value: "" },
        })
      : Object.entries({
          ...enums,
        });
  }, [enums, passive]);

  const setEnumValue = useCallback(
    (uuidKey: string, newValue?: string) => {
      const newObj = produce(enums ?? {}, (draft) => {
        if (draft[uuidKey]) {
          draft[uuidKey].value = newValue ?? "";
        } else {
          draft[uuidKey] = {
            value: newValue ?? "",
            key: "",
          };
        }
      });

      setEnums(newObj);
    },
    [enums, setEnums],
  );

  const setEnumKey = useCallback(
    (uuidKey: string, newKey?: string) => {
      const newObj = produce(enums ?? {}, (draft) => {
        if (draft[uuidKey]) {
          draft[uuidKey].key = newKey ?? "";
        } else {
          draft[uuidKey] = {
            value: "",
            key: newKey ?? "",
          };
        }
      });

      setEnums(newObj);
    },
    [enums, setEnums],
  );

  const deleteEnumRow = useCallback(
    (uuidKey: string) => {
      const newEnums = produce(enums ?? {}, (draft) => {
        delete draft[uuidKey];
        return draft;
      });

      setEnums(newEnums ?? {});
    },
    [enums, setEnums],
  );

  return (
    <div>
      {enumEntries.map(([uuidKey, { key, value }], index) => (
        <div key={uuidKey} className="flex w-full items-center gap-5 md:w-1/2">
          {multiple ? <CheckboxOffIcon /> : <RadioButtonOffIcon />}

          <Input
            value={value}
            placeholder={intl.formatMessage({
              id: "custom-field.input.enum-options.value",
              defaultMessage: "Fill in to create another option",
            })}
            onChange={(event) => {
              setEnumValue(uuidKey, event.target.value);
            }}
            passive={passive}
          />

          <Input
            value={key}
            placeholder={intl.formatMessage({
              id: "generic.label.backend-id",
              defaultMessage: "Backend ID",
            })}
            onChange={(event) => {
              setEnumKey(uuidKey, event.target.value);
            }}
            passive={passive}
          />

          {!passive ? (
            <div className="pb-1">
              {index !== enumEntries.length - 1 ? (
                <DeleteActionButton onPress={() => deleteEnumRow(uuidKey)} />
              ) : (
                <div className="w-[34px]" />
              )}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default EnumOptions;
