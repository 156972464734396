import { TableProps } from "@new-black/lyra";

import ErrorBoundary from "components/suite-ui/error-boundary";
import Text from "components/suite-ui/text";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";
import ProductFilterList from "../list";
import { DefaultProductFilterActions } from "../product-filter-actions";
import { ProductFilterProvider } from "../product-filter-context";
import ProductFilterModal from "../product-filter-modal";
import { DefaultProductFilterRowActions } from "../product-filter-row-actions";
import { EvaFilterModel } from "../types";

export interface IProductFilterTableProps {
  /** Current product property filters */
  productFilter?: EvaFilterModel;
  /** Callback fired when the filter is modified */
  updateProductFilter?: (filters?: EvaFilterModel) => void;
  /** Async callback fired when the filter is modified.*/
  updateProductFilterAsync?: (filters?: EvaFilterModel) => Promise<any>;
  /** Optional error message related to the product filter. If set, a warning icon is displayed in the table and the error is shown when hovering it. */
  error?: string;
  /** If true, the add action will be hidden */
  disableAdd?: boolean;
  /** If true, the edit action will be hidden */
  disableEdit?: boolean;
  /** If true, the delete action will be hidden */
  disableDelete?: boolean;
  /** Image to display as a fallback when the products don't have a primary image. */
  placeholderImageUrl?: string;
  /** Title of the table */
  title: string;
  /** className used on the title container to customize the styling  */
  titleClassName?: string;
  /** Table options */
  tableOptions?: TableProps<IEVAFilterArrayItem>["options"];
}

/**
 * Component used to manage a product search filter. Renders a list of filters as well as actions that allow managing the list and previewing the search result.
 */
const ProductFilterTable = ({
  disableAdd,
  disableDelete,
  disableEdit,
  error,
  placeholderImageUrl,
  productFilter,
  tableOptions,
  title,
  titleClassName,
  updateProductFilter,
  updateProductFilterAsync,
}: IProductFilterTableProps) => (
  <ErrorBoundary>
    <ProductFilterProvider
      productFilter={productFilter}
      updateProductFilter={updateProductFilter}
      updateProductFilterAsync={updateProductFilterAsync}
      placeholderImageUrl={placeholderImageUrl}
      disableEdit={disableEdit}
      disableDelete={disableDelete}
      disableAdd={disableAdd}
      maximumDisplayedValuesInList={5}
      error={error}
    >
      <div className="p-5 pb-3">
        <div className="flex items-center justify-between">
          <Text variant="h3" className={titleClassName}>
            {title}
          </Text>

          <ErrorBoundary>
            <DefaultProductFilterActions />
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary>
        <ProductFilterList
          customRowActions={
            disableDelete && disableEdit
              ? undefined
              : (rowData) => DefaultProductFilterRowActions({ rowData, deleteModalVariant: "lyra" })
          }
          options={tableOptions}
        />
      </ErrorBoundary>
      <ProductFilterModal />
    </ProductFilterProvider>
  </ErrorBoundary>
);

export default ProductFilterTable;
