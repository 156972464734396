import { forwardRef } from "react";

import { ListItemIcon, ListItemIconProps, MenuItem, MenuItemProps } from "@material-ui/core";
import { cva } from "class-variance-authority";

import Input, { InputProps } from "components/suite-ui/input";

export const StyledSelectInput = (props: InputProps) => (
  <Input className="[&_.MuiSelect-root]:flex [&_.MuiSelect-root]:items-center " {...props} />
);

export const StyledListItemIcon = (props: ListItemIconProps) => (
  <ListItemIcon className="mr-5 min-w-[unset] text-xl " {...props} />
);

export const StyledMenuItem = forwardRef<
  HTMLLIElement,
  Omit<MenuItemProps, "button"> & { divider?: boolean }
>((props, ref) => (
  <MenuItem className={menuItemStyled({ hasDivider: props.divider })} {...props} ref={ref} />
));

StyledMenuItem.displayName = "StyledMenuItem";

const menuItemStyled = cva("w-full", {
  variants: {
    hasDivider: {
      true: "border-b-[1px] border-solid border-[color:var(--legacy-eva-color-light-3)]",
    },
  },
  defaultVariants: {
    hasDivider: false,
  },
});
