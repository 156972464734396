import { useLoadableState } from "hooks/use-loadable-state";

import { getPriceListByIdResultSelector } from "./state";

const useGetPriceListById = (id?: number) => {
  const {
    isLoading,
    isLoadingWithoutResponse,
    value: priceList,
  } = useLoadableState(getPriceListByIdResultSelector(id));

  return {
    priceList,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export default useGetPriceListById;
