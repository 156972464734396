import { useCallback } from "react";

import useGetAssetsUrl from "./use-get-assets-url";

const useOpenBlobsList = () => {
  const assetsUrl = useGetAssetsUrl();

  const openBlobsList = useCallback(
    (blobs: Array<string | undefined>) => {
      blobs.forEach((blob) => {
        if (assetsUrl && blob) {
          window.open(`${assetsUrl}/blob/${blob}`, "_blank");
        }
      });
    },
    [assetsUrl],
  );

  return openBlobsList;
};

export default useOpenBlobsList;
