export default {
  module: {
    path: "/orders",
  },
  orders: {
    overview: {
      path: "/orders/orders",
    },
    details: {
      path: "/orders/orders/:id",
      verify: {
        path: "/orders/orders/:id/verify",
      },
    },
    detailsNew: {
      path: "/orders/orders-new/:id",

      orderLines: {
        path: "/orders/orders-new/:id/order-lines",
      },

      payments: {
        path: "/orders/orders-new/:id/payments",
      },

      shipments: {
        path: "/orders/orders-new/:id/shipments",
      },

      relatedOrders: {
        path: "/orders/orders-new/:id/related-orders",
      },

      orderHistory: {
        path: "/orders/orders-new/:id/order-history",
      },

      documents: {
        path: "/orders/orders-new/:id/documents",
      },

      bookings: {
        path: "/orders/orders-new/:id/bookings",
      },

      exports: {
        path: "/orders/orders-new/:id/exports",
      },

      interactionLogs: {
        path: "/orders/orders-new/:id/interaction-logs",
      },

      orderlineDetails: {
        path: "/orders/orders-new/:id/orderline/:orderlineid",
      },

      // Sidebar Card Paths
      editOrder: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-order",
      },
      transferOrder: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/transfer-order",
      },
      duplicateToPOS: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/duplicate-to-pos",
      },
      editRemark: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-remark",
      },
      editCustomFields: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-custom-fields",
      },
      editCustomer: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-customer",
      },
      editCustomerDetachCustomer: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-customer/detach-customer",
      },
      editCustomerAddressActions: {
        path: "/orders/orders-new/:id/:tab/:orderlineid?/edit-customer/:addresstype/:addressaction",
      },
    },
    orderlineDetails: {
      path: "/orders/orders/:id/:orderlineid",
    },
  },
  orchestration: {
    overview: {
      path: "/orders/orchestration",
    },
    details: {
      path: "/orders/orchestration/:id",
    },
    previewOrderFulfillment: {
      path: "/orders/orchestration/preview-order-fulfillment",
    },
  },
  exportLogs: {
    overview: {
      path: "/orders/export-logs",
    },
  },
  checkoutOptions: {
    overview: {
      path: "/orders/checkout-options",
    },
    new: {
      path: "/orders/checkout-options/new",
    },
    edit: {
      path: "/orders/checkout-options/:id",
    },
    delete: {
      path: "/orders/checkout-options/:id/delete",
    },
    category: {
      edit: {
        path: "/orders/checkout-options/category/:categoryId",
      },
      new: {
        path: "/orders/checkout-options/category/new",
      },
      delete: {
        path: "/orders/checkout-options/category/:categoryId/delete",
      },
    },
    reorder: {
      path: "/orders/checkout-options/reorder",
    },
  },

  customOrderStatuses: {
    path: "/orders/custom-order-statuses",
    oldAdminPath: "/orderstatus",
  },

  customOrderTypes: {
    path: "/orders/custom-order-types",
    oldAdminPath: "/ordertypes",
  },

  // WALDO 2.0
  orderCustomStatuses: {
    overview: {
      path: "/orders/order-custom-statuses",
      new: {
        path: "/orders/order-custom-statuses/new",
      },
      edit: {
        path: "/orders/order-custom-statuses/:id/edit",
      },
      delete: {
        path: "/orders/order-custom-statuses/:id/delete",
      },
    },
  },

  // WALDO 2.0
  orderCustomTypes: {
    overview: {
      path: "/orders/order-custom-types",
    },
    new: {
      path: "/orders/order-custom-types/new",
    },
    details: {
      path: "/orders/order-custom-types/:id",
      edit: {
        path: "/orders/order-custom-types/:id/edit",
      },
    },
  },
} as const;
