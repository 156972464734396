import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listPaymentTypesLoaderQuery,
  serviceQuery: listPaymentTypesQuery,
  serviceQueryKeys: listPaymentTypesQueryKeys,
  useServiceQueryHook: useListPaymentTypesQuery,
} = createServiceQuery(CoreManagement.ListPaymentTypes, true);

export const {
  serviceLoaderQuery: listPaymentSettingsLoaderQuery,
  useServiceQueryHook: useListPaymentSettingsQuery,
} = createServiceQuery(CoreManagement.ListOrganizationUnitSetPaymentTypes, true);

export const {
  serviceLoaderQuery: getPaymentTypeByIDLoaderQuery,
  serviceQueryKeys: getPaymentTypeByIDQueryKeys,
  useServiceQueryHook: useGetPaymentTypeByIDQuery,
} = createServiceQuery(CoreManagement.GetPaymentTypeByID, true);
