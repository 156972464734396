/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Controlled as ControlledImageZoom } from "react-medium-image-zoom";

import { Button, SvgIcon } from "@new-black/lyra";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { cva } from "class-variance-authority";
import classNames from "classnames";
import { isNil } from "lodash";

import NoImage from "assets/images/no_image.svg?react";
import ExpandableText, { IExpandableTextProps } from "components/suite-ui/expandable-text";
import Grid from "components/suite-ui/grid";
import Text, { TextProps } from "components/suite-ui/text";
import useProductIDProperty from "hooks/suite-react-hooks/use-product-id-property";

export {
  ProductSummary as LyraProductSummary,
  type IProductSummaryProps as ILyraProductSummaryProps,
} from "./lyra";

const styledTextClasses = cva("block overflow-hidden whitespace-nowrap text-ellipsis", {
  variants: {
    strikeTrough: {
      true: "line-through opacity-30",
    },
    disabled: {
      true: "text-[color:#9b9b9b]",
    },
  },
});

const StyledText = ({
  className,
  disabled,
  fontSize,
  strikeTrough,
  ...props
}: { strikeTrough?: boolean; fontSize?: number; disabled?: boolean } & TextProps) => (
  <Text
    {...props}
    className={classNames(styledTextClasses({ strikeTrough, disabled }), className)}
    style={fontSize ? { fontSize: `${fontSize}px`, lineHeight: `${fontSize}px` } : {}}
  />
);

const DEFAULT_EXPANDABLE_TEXT_PROPS = (text: string) =>
  ({
    spaceBeforeLessAction: true,
    spaceBeforeMoreAction: true,
    variant: "inherit",
    linkButtonVariant: "body2",
    maxLength: 40,
    text,
  } as const);

export interface IProductSummaryProps {
  /** Name of the product you want to display  */
  productName: string;
  /** ID of the product you want to display  */
  productID?: number | string;
  /** custom ID of the product you want to display  */
  customID?: number | string;
  /** backend ID of the product you want to display  */
  backendID?: number | string;
  /** Barcode of the product you want to display  */
  barcode?: number | string;
  /** Wether the product holds warranty or not */
  isWarranty?: boolean;
  /** ID of a blob stored in EVA that contains the image of the product */
  productImageBlobID?: string;
  /** Placeholder image to be shown when the product does not have an image. By default a gray box is shown. */
  placeholderImageUrl?: string;
  /** If true, the component has a lower opacity and the text has a line trough. Use this to indicate that the product is disabled / unavailable. */
  strikeTrough?: boolean;
  /** Width and height of the image in pixels. Defaults to 50 */
  imageSize?: number;
  /** Width and height of the enlarged image in pixels. Defaults to 500 */
  enlargedImageSize?: number;
  /** Font size of the product name in pixels. Defaults to 13 */
  productNameFontSize?: number;
  /** Font size of the product ID in pixels. Defaults to 12 */
  productIDFontSize?: number;
  /** Font size of the barcode in pixels. Defaults to 12 */
  barcodeFontSize?: number;
  /* If true, image can be clicked to open an enlarged version */
  enlargeImage?: boolean;
  /** If true, image will be hidden */
  hideImage?: boolean;
  /** If true, product information will be hidden */
  hideProductInfo?: boolean;
  /** Show a label for the Product ID / Custom ID / Backend ID. Defaults to `false` */
  showIDLabel?: boolean;
  /** Show a label for the barcode. Defaults to `false` */
  showBarcodeLabel?: boolean;
  /** Classname of the first html element */
  className?: string;
  /** Makes the product name render as an ExpandableText component */
  expandableProductName?: boolean;
  /** Max characters to show when expandableProductName is true */
  expandableProductNameProps?:
    | IExpandableTextProps
    | ((defaultProps: ReturnType<typeof DEFAULT_EXPANDABLE_TEXT_PROPS>) => IExpandableTextProps);
  /** If true, the component has a lower opacity. Use this to indicate that the product is disabled / unavailable without having the strikethrough available. */
  disabled?: boolean;
}

export const ProductSummary = ({
  productName,
  productID,
  customID,
  backendID,
  barcode,
  isWarranty,
  productImageBlobID,
  placeholderImageUrl,
  strikeTrough,
  imageSize = 50,
  productNameFontSize = 13,
  productIDFontSize = 12,
  barcodeFontSize = 12,
  enlargeImage,
  enlargedImageSize = 500,
  hideImage = false,
  hideProductInfo = false,
  showIDLabel = false,
  showBarcodeLabel = false,
  className,
  children,
  expandableProductName = false,
  disabled,
  expandableProductNameProps = (defaultProps) => ({ ...defaultProps, text: productName ?? "" }),
}: PropsWithChildren<IProductSummaryProps>) => {
  const [productImageError, setProductImageError] = useState(false);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);
  const applicationConfiguration = hooks.useGetState(state.core.applicationConfigurationState);

  const assetsUrl = useMemo(
    () => (applicationConfiguration?.Configuration["Urls:Assets"] as string) ?? "",
    [applicationConfiguration],
  );

  const imageUrl = useMemo(() => {
    if (!!assetsUrl && !!productImageBlobID) {
      return `${assetsUrl}/image/${imageSize}/${imageSize}/${productImageBlobID}.png`;
    }
    return placeholderImageUrl;
  }, [assetsUrl, imageSize, placeholderImageUrl, productImageBlobID]);

  const enlargedImageUrl = useMemo(() => {
    if (!!assetsUrl && !!productImageBlobID) {
      return `${assetsUrl}/image/${enlargedImageSize}/${enlargedImageSize}/${productImageBlobID}.png`;
    }
    return undefined;
  }, [assetsUrl, enlargedImageSize, productImageBlobID]);

  const { getProperty: getIdProp, idProperty } = useProductIDProperty();

  const itemID = useMemo(
    () => getIdProp([productID, customID, backendID]),
    [backendID, customID, getIdProp, productID],
  );

  const IDLabel = useMemo(() => {
    if (showIDLabel) {
      switch (idProperty) {
        case "backend_id":
          return (
            <FormattedMessage
              id="generic.label.backend-id-value"
              defaultMessage="Backend ID: {value}"
              values={{ value: itemID }}
            />
          );
        case "custom_id":
          return (
            <FormattedMessage
              id="generic.label.custom-id-value"
              defaultMessage="Custom ID: {value}"
              values={{ value: itemID }}
            />
          );
        case "product_id":
        default:
          return (
            <FormattedMessage
              id="generic.label.product-id-value"
              defaultMessage="Product ID: {value}"
              values={{ value: itemID }}
            />
          );
      }
    }

    return null;
  }, [idProperty, itemID, showIDLabel]);

  const expandableProductNameTextProps = useMemo(
    () =>
      typeof expandableProductNameProps === "function"
        ? expandableProductNameProps(DEFAULT_EXPANDABLE_TEXT_PROPS(productName ?? ""))
        : expandableProductNameProps,
    [expandableProductNameProps, productName],
  );

  return (
    <Grid container wrap="nowrap" className={className} alignItems="center">
      {hideImage ? null : (
        <Grid item>
          <div
            className="mr-5"
            style={{
              width: isNaN(imageSize) ? 50 : imageSize,
              height: isNaN(imageSize) ? 50 : imageSize,
              backgroundColor: imageUrl ? undefined : "var(--legacy-eva-color-transparent-50)",
            }}
          >
            {imageUrl && !productImageError ? (
              <Button className="!bg-transparent !p-0">
                <ControlledImageZoom
                  isZoomed={isImageEnlarged}
                  onZoomChange={(value) => {
                    if (enlargeImage) {
                      setIsImageEnlarged(value);
                    }
                  }}
                  zoomImg={{
                    src: enlargedImageUrl,
                    alt: "product",
                  }}
                  zoomMargin={100}
                >
                  <img
                    className={classNames({
                      "!cursor-auto": !enlargeImage,
                      "opacity-30": strikeTrough || disabled,
                    })}
                    src={imageUrl}
                    alt="product"
                    style={{
                      width: isNaN(imageSize) ? 50 : imageSize,
                      height: isNaN(imageSize) ? 50 : imageSize,
                    }}
                    onError={(e) => setProductImageError(true)}
                  />
                </ControlledImageZoom>
              </Button>
            ) : (
              <NoImage
                width={isNaN(imageSize) ? 50 : imageSize}
                height={isNaN(imageSize) ? 50 : imageSize}
              />
            )}
          </div>
        </Grid>
      )}
      {hideProductInfo ? null : (
        <Grid zeroMinWidth item xs>
          <Grid
            container
            direction="column"
            justifyContent="center"
            wrap="nowrap"
            className="gap-[5px]"
          >
            <Grid zeroMinWidth item>
              <div>
                <StyledText
                  strikeTrough={strikeTrough}
                  fontSize={productNameFontSize}
                  disabled={disabled}
                >
                  {expandableProductName ? (
                    <span className="block max-w-[35ch] flex-wrap overflow-visible !whitespace-normal text-[13px] !leading-[13px] [text-overflow:unset]">
                      <ExpandableText {...expandableProductNameTextProps} />
                    </span>
                  ) : (
                    <span>{productName}</span>
                  )}
                </StyledText>
              </div>
            </Grid>
            {isNil(itemID) ? null : (
              <Grid zeroMinWidth item>
                <StyledText
                  variant="body2"
                  strikeTrough={strikeTrough}
                  disabled={disabled}
                  fontSize={productIDFontSize}
                >
                  {IDLabel ?? itemID}
                </StyledText>
              </Grid>
            )}
            {isNil(isWarranty) ? null : (
              <Grid zeroMinWidth item>
                <div className="flex items-center gap-1">
                  {isWarranty ? (
                    <SvgIcon name="verified-checkmark" className="text-success" />
                  ) : (
                    <SvgIcon name="clear" className="text-error" />
                  )}
                  <FormattedMessage id="generic.label.warranty" defaultMessage="Warranty" />
                </div>
              </Grid>
            )}
            {isNil(barcode) ? null : (
              <Grid zeroMinWidth item>
                <StyledText
                  variant="body2"
                  strikeTrough={strikeTrough}
                  fontSize={barcodeFontSize}
                  disabled={disabled}
                >
                  {showBarcodeLabel ? (
                    <FormattedMessage
                      id="generic.label.barcode-:"
                      defaultMessage="Barcode: {value}"
                      values={{ value: barcode }}
                    />
                  ) : (
                    barcode
                  )}
                </StyledText>
              </Grid>
            )}
          </Grid>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
