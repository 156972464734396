import { builders } from "@springtree/eva-sdk-react-recoil";
import { createGetterSetterSelector } from "@springtree/eva-sdk-react-recoil/lib/helpers/create-selectors";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

export const roleSetsListDefaultRequest: EVA.Core.Management.ListRoleSets = {
  PageConfig: {
    Start: 0,
    Limit: 10,
  },
};

const roleSetListState = builders.buildServiceState({
  service: CoreManagement.ListRoleSets,
  key: "roleSetListState",
  requireEmployee: true,
  allowEmptyRequest: false,
  defaultRequest: roleSetsListDefaultRequest,
});

const roleSetsListPageConfigSelector = createGetterSetterSelector({
  state: roleSetListState.request,
  key: "roleSetListState:PageConfig",
  defaultValue: roleSetsListDefaultRequest.PageConfig,
  getData: (req) => req?.PageConfig,
  setData: (req, newValue) => ({ ...req, PageConfig: { ...newValue } }),
});

export const roleSetsListPageConfigStartSelector = createGetterSetterSelector({
  state: roleSetsListPageConfigSelector,
  key: "roleSetListState:PageConfig:Start",
  defaultValue: roleSetsListDefaultRequest.PageConfig?.Start,
  getData: (req) => req?.Start ?? roleSetsListDefaultRequest.PageConfig?.Start,
  setData: (req, newValue) => ({ ...req, Start: newValue }),
});

export const roleSetsListPageConfigLimitSelector = createGetterSetterSelector({
  state: roleSetsListPageConfigSelector,
  key: "roleSetListState:PageConfig:Limit",
  defaultValue: roleSetsListDefaultRequest.PageConfig?.Limit,
  getData: (req) => req?.Limit ?? roleSetsListDefaultRequest.PageConfig?.Limit,
  setData: (req, newValue) => ({ ...req, Limit: newValue }),
});

export const roleSetsListTotalSelector = selector({
  key: "roleSetListState:Total",
  get: ({ get }) => get(roleSetListState.response)?.Result?.Total,
});

export default roleSetListState;
