import { cva } from "class-variance-authority";

import Text from "components/suite-ui/text";

import { ISearchListFieldBaseProps } from "../types";

const helperTextStyles = cva(["select-none text-[color:#666666] pl-4"], {
  variants: {
    error: { true: "text-error" },
    warning: { true: "" },
  },
  compoundVariants: [
    {
      error: false,
      warning: true,
      class: "text-[color:var(--color-warning-3)]",
    },
  ],
});

export const SearchListFieldHelperText = ({
  error,
  helperText,
  warning,
}: Pick<ISearchListFieldBaseProps<any>, "error" | "warning"> & { helperText: string }) => (
  <Text variant="caption" className={helperTextStyles({ error: !!error, warning: !!warning })}>
    {helperText}
  </Text>
);
