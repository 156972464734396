import { ReactNode } from "react";

import { SortableContext } from "@dnd-kit/sortable";

import useSortingStrategy from "./hooks/use-sorting-strategy";
import DroppableContainer from "./droppable-container";

interface ISortableContainerProps {
  innerItems: string[];
  droppableId: string;
  direction?: "vertical" | "horizontal" | "grid";
  children: ReactNode;
}

const SortableContainer = ({
  children,
  direction,
  droppableId,
  innerItems,
}: ISortableContainerProps) => {
  const sortingStrategy = useSortingStrategy(direction);

  return (
    <SortableContext items={innerItems} strategy={sortingStrategy}>
      <DroppableContainer id={droppableId}>{children}</DroppableContainer>
    </SortableContext>
  );
};

export default SortableContainer;
