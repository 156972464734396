import { useEffect, useMemo } from "react";

import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useRecoilState } from "recoil";

import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";
import { getDeviceTypesServiceState } from "store/device-types";

/**
 * Service state for the available printer stations per organization unit
 */
export const listStationsForOrganizationUnitServiceState = builders.buildServiceFamilyState({
  key: "Core:ListStationsForOrganizationUnit:Printers",
  service: Core.ListStationsForOrganizationUnit,
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
});

/**
 * Hook for listing printers from a organization unit
 */
export const useListPrintersForOrganizationUnit = (organizationUnitID?: number) => {
  const deviceTypes = hooks.useGetServiceResponse(getDeviceTypesServiceState)?.DeviceTypes;
  const isDeviceTypesResponseLoading = hooks.useIsLoading({
    state: getDeviceTypesServiceState.response,
  });

  const printerTypeId = useMemo(
    () => deviceTypes?.find((deviceType) => deviceType.Name === "ThermalPrinter")?.ID,
    [deviceTypes],
  );
  const [listStationsForOrganizationUnitRequest, setListStationsForOrganizationUnitRequest] =
    useRecoilState(listStationsForOrganizationUnitServiceState.request(organizationUnitID));

  const isStationsResponseLoading = hooks.useIsLoading({
    state: listStationsForOrganizationUnitServiceState.response(organizationUnitID),
  });
  const stationsResponse = hooks.useGetState(
    listStationsForOrganizationUnitServiceState.response(organizationUnitID),
  )?.Result;

  const isLoadingWithoutResponse = useMemo(
    () => isStationsResponseLoading && !stationsResponse,
    [isStationsResponseLoading, stationsResponse],
  );

  const hasAvailableStations = useMemo(
    () => stationsResponse && !!stationsResponse.Page?.length,
    [stationsResponse],
  );

  useHandleSWRFamily(listStationsForOrganizationUnitServiceState, organizationUnitID);

  useEffect(() => {
    if (
      organizationUnitID &&
      printerTypeId &&
      (listStationsForOrganizationUnitRequest?.OrganizationUnitID !== organizationUnitID ||
        listStationsForOrganizationUnitRequest.DeviceTypeID !== printerTypeId)
    ) {
      setListStationsForOrganizationUnitRequest({
        DeviceTypeID: printerTypeId,
        OrganizationUnitID: organizationUnitID,
      });
    }
  }, [
    printerTypeId,
    organizationUnitID,
    setListStationsForOrganizationUnitRequest,
    listStationsForOrganizationUnitRequest?.DeviceTypeID,
    listStationsForOrganizationUnitRequest?.OrganizationUnitID,
  ]);

  const isLoading = useMemo(
    () => isDeviceTypesResponseLoading || isStationsResponseLoading,
    [isDeviceTypesResponseLoading, isStationsResponseLoading],
  );

  return {
    stationsResponse,
    isLoading,
    isLoadingWithoutResponse,
    hasAvailableStations,
  };
};
