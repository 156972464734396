import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { IChapter } from "../chapter-finder.types";

export const useStockChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: rtsAllowed } = useHasFunctionality(
    Functionalities.RETURN_TO_SUPPLIER_REQUESTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: replenishmentsAllowed } = useHasFunctionality(
    Functionalities.REPLENISHMENTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: assortementsAllowed } = useHasFunctionality(
    Functionalities.ASSORTMENTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: purchaseOrdersAllowed } = useHasFunctionality(
    Functionalities.PURCHASE_ORDERS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: orderFulfillmentsAllowed } = useHasFunctionality(
    Functionalities.ORDER_FULFILLMENTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: allocationAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SUPPLIERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewStockAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.STOCK,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: viewStockMutationsAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.STOCK_MUTATIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: productCapacityPlansAllowed } = useHasFunctionality(
    Functionalities.PRODUCT_CAPACITY_PLANS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: shippingRestrictionsAllowed } = useHasFunctionality(
    Functionalities.SHIPPING_RESTRICTIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: shippingMethodsAllowed } = useHasFunctionality(
    Functionalities.SHIPPING_METHODS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: carriersAllowed } = useHasFunctionality(
    Functionalities.CARRIERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: stockLabelsAllowed } = useHasFunctionality(
    Functionalities.STOCK_LABELS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: receiveShipmentsAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.RECEIVE_SHIPMENTS,
  );

  const stockChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (rtsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.return-to-supplier",
          defaultMessage: "Return to supplier",
        }),
        path: "/rts",
      });
    }

    if (replenishmentsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.replenishments",
          defaultMessage: "Replenishments",
        }),
        path: "/replenishments",
      });
    }

    if (allocationAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.allocation",
          defaultMessage: "Allocation",
        }),
        path: "/allocation",
      });
    }

    if (assortementsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.assortments",
          defaultMessage: "Assortments",
        }),
        path: "/assortments",
      });
    }

    if (purchaseOrdersAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.purchase-orders",
          defaultMessage: "Purchase orders",
        }),
        path: "/purchase-orders",
      });
    }

    if (orderFulfillmentsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.order-fulfillments",
          defaultMessage: "Order fulfillments",
        }),
        path: "/order-fulfillments",
      });
    }

    if (productCapacityPlansAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.product-capacity-plans",
          defaultMessage: "Product capacity plans",
        }),
        path: "/product-capacity-plans",
      });
    }
    chapters.push({
      title: intl.formatMessage({
        id: "generic.label.availability",
        defaultMessage: "Availability",
      }),
      path: "/availability",
    });

    if (carriersAllowed || shippingRestrictionsAllowed || shippingMethodsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.shipping",
          defaultMessage: "Shipping",
        }),
        path: "/shipping",
      });
    }

    if (viewStockAllowed || viewStockMutationsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.overview-and-mutations",
          defaultMessage: "Overview and mutations",
        }),
        path: "/overview-and-mutations",
      });
    }

    if (receiveShipmentsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.receive-goods",
          defaultMessage: "Receive goods",
        }),
        path: "/receive-goods",
      });
    }

    if (stockLabelsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.stock-locations",
          defaultMessage: "Stock locations",
        }),
        path: "/stock-locations",
      });
    }

    return chapters;
  }, [
    allocationAllowed,
    assortementsAllowed,
    carriersAllowed,
    intl,
    orderFulfillmentsAllowed,
    productCapacityPlansAllowed,
    purchaseOrdersAllowed,
    receiveShipmentsAllowed,
    replenishmentsAllowed,
    rtsAllowed,
    shippingMethodsAllowed,
    shippingRestrictionsAllowed,
    stockLabelsAllowed,
    viewStockAllowed,
    viewStockMutationsAllowed,
  ]);

  return stockChapters;
};
