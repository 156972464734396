import { useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

import Input from "components/suite-ui/input";
import useRecoilDebouncedValue from "hooks/suite-react-hooks/use-recoil-debounced-value";
import {
  listVisibilityGroupsQuery,
  useGetVisibilityGroupByIDQuery,
  useListVisibilityGroupsQuery,
} from "models/visibility-groups";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import {
  visibilityGroupDetailsState,
  visibilityGroupsListRequestNameSelector,
  visibilityGroupsListSelector,
  visibilityGroupsListState,
} from "./visibility-group-autocomplete.state";

interface IVisibilityGroupAutocompleteProps {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  passive?: boolean;
  required?: boolean;
  familyKey?: string;
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const VisibilityGroupAutocomplete = ({
  error,
  familyKey,
  helperText,
  label,
  name,
  onChange,
  passive,
  required,
  value,
}: IVisibilityGroupAutocompleteProps) => {
  const serviceKey = useMemo(() => familyKey ?? name, [familyKey, name]);

  const visibilityGroupGeneralInformation = hooks.useGetState(
    visibilityGroupDetailsState.response(value),
  );
  const selectedVisibilityGroupInfoIsLoading = hooks.useIsLoading({
    state: visibilityGroupDetailsState.response(value),
  });

  const visiblityGroups = hooks.useGetState(visibilityGroupsListSelector(serviceKey));
  const visibilityGroupListLoading = hooks.useIsLoading({
    state: visibilityGroupsListState.response(serviceKey),
  });

  const isLoading = useMemo(
    () => selectedVisibilityGroupInfoIsLoading || visibilityGroupListLoading,
    [selectedVisibilityGroupInfoIsLoading, visibilityGroupListLoading],
  );

  const [nameFilter, setNameFilter] = useRecoilDebouncedValue(
    visibilityGroupsListRequestNameSelector(serviceKey),
  );

  // Keep a state of previously selected VG to prevent deselecting on input change (used in `onBlur`)
  const [prevSelected, setPrevSelected] = useState<{ ID: number; Name: string } | undefined>();

  useEffect(() => {
    if (value && visibilityGroupGeneralInformation) {
      setPrevSelected(visibilityGroupGeneralInformation);
    }
  }, [value, visibilityGroupGeneralInformation]);

  const items = useMemo(() => {
    const visibilityGroupList = visiblityGroups?.map((visibilityGroup) => ({
      ID: visibilityGroup.ID,
      Name: visibilityGroup.Name,
    }));
    if (visibilityGroupList) {
      return value &&
        visibilityGroupGeneralInformation &&
        !visibilityGroupList.find((visibilityGroup) => visibilityGroup.ID === value)
        ? [...visibilityGroupList, { ID: value, Name: visibilityGroupGeneralInformation?.Name }]
        : visibilityGroupList;
    }
    return undefined;
  }, [value, visibilityGroupGeneralInformation, visiblityGroups]);

  return passive ? (
    <Input
      name={name}
      label={label}
      passive={passive}
      required={required}
      value={visibilityGroupGeneralInformation?.Name ?? ""}
    />
  ) : (
    <Autocomplete
      clearOnBlur
      selectOnFocus
      autoHighlight
      handleHomeEndKeys
      loading={isLoading}
      value={value ?? null}
      inputValue={nameFilter ?? ""}
      id={`visibility-group-autocomplete:${name}`}
      options={items?.map((item) => item.ID) ?? []}
      getOptionSelected={(option, selectedValue) => option === selectedValue}
      getOptionLabel={(option) => items?.find((item) => item.ID === option)?.Name ?? ""}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange((newValue ?? undefined) as number | undefined);
        } else {
          onChange(undefined);
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        // Prevent resetting input value on input
        if (reason === "input" || reason === "clear" || (reason === "reset" && newInputValue)) {
          setNameFilter(newInputValue);
        }
      }}
      onBlur={() => {
        // On single-autocomplete, prevent deselecting VG if the input has changed
        if (!value && nameFilter) {
          onChange(prevSelected?.ID);
          setNameFilter(prevSelected?.Name);
        }
      }}
      renderInput={(params) => (
        <Input
          {...params}
          required={required}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

VisibilityGroupAutocomplete.defaultProps = {
  error: undefined,
  helperText: undefined,
  label: undefined,
  passive: undefined,
  required: undefined,
  familyKey: undefined,
};

export default VisibilityGroupAutocomplete;

export const generateVisibilityGroupLyraSearchListField = (visibilityGroupIDBlacklist?: number[]) =>
  SearchListFieldGenerator<
    CoreManagement.ListVisibilityGroups,
    EVA.Core.Management.ListVisibilityGroupsResponse.VisibilityGroupDto,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.visibility-group",
      defaultMessage: "Visibility group",
    }),
    getItemFromResponse: (res) =>
      res?.Result.Page.filter((item) => !visibilityGroupIDBlacklist?.includes(item.ID)) ?? [],
    useItemByID: (id, listItems) => {
      const itemFromList = useMemo(
        () => listItems?.find((item) => item.ID === id),
        [id, listItems],
      );
      const shouldFetch = useMemo(() => !itemFromList && id !== undefined, [id, itemFromList]);
      const { data, isFetching } = useGetVisibilityGroupByIDQuery(
        shouldFetch ? { ID: id! } : undefined,
        {},
      );
      const item = useMemo(() => itemFromList ?? data, [data, itemFromList]);
      return { data: item, isLoading: shouldFetch ? isFetching : false };
    },
    useItemsByID: (ids, listItems) => {
      const itemsFromList = useMemo(
        () => ids?.map((id) => listItems?.find((item) => item.ID === id)),
        [ids, listItems],
      );
      const shouldFetch = useMemo(
        () =>
          !itemsFromList ||
          itemsFromList.some((item) => item === undefined) ||
          itemsFromList.length === 0,
        [itemsFromList],
      );
      const { data, isFetching } = useListVisibilityGroupsQuery(
        shouldFetch
          ? {
              PageConfig: {
                Start: 0,
                Filter: { IDs: ids },
                Limit: Math.max((ids ?? []).length, 1),
              },
            }
          : undefined,
        {},
      );
      const items = useMemo(
        () =>
          (itemsFromList ?? data?.Result.Page ?? []).filter(
            (item): item is EVA.Core.Management.ListVisibilityGroupsResponse.VisibilityGroupDto =>
              item !== undefined,
          ),
        [data, itemsFromList],
      );
      return { data: items, isLoading: shouldFetch ? isFetching : false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listVisibilityGroupsQuery,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name ?? "",
        initialRequest: {
          PageConfig: {
            Start: 0,
            Filter: { Name: undefined },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        setQueryRequest: (req, value) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: { ...req?.PageConfig?.Filter, Name: value },
          },
        }),
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton:
            (resp?.Result.Total ?? 0) > (resp?.Result.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
