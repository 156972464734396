import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useQuery } from "@tanstack/react-query";

import { MaterialAutocomplete } from "components/suite-ui/autocomplete";
import { listTimeZonesQuery } from "models/application";
import { ONE_HOUR } from "util/base-values";

interface ITimeZoneAutocompleteProps {
  passive?: boolean;
  defaultValue?: string;
  error?: boolean;
  helperText?: string;
  name: string;
  required?: boolean;
}

// TODO: remove this component after all usages are replaced with the new, Lyra-based SLF
const TimeZoneAutocomplete = ({
  defaultValue,
  error,
  helperText,
  name,
  passive,
  required,
}: ITimeZoneAutocompleteProps) => {
  const intl = useIntl();

  const { data: listTimeZonesData, isLoading } = useQuery({
    ...listTimeZonesQuery({}),
    staleTime: ONE_HOUR,
  });

  const timeZones = useMemo(() => listTimeZonesData?.Result, [listTimeZonesData?.Result]);

  const [value, setValue] = useState<string | undefined>(defaultValue);

  const [inputValue, setInputValue] = useState<string | undefined>(
    timeZones?.find((c) => c.ID === defaultValue)?.ID,
  );

  return (
    <>
      <MaterialAutocomplete
        label={intl.formatMessage({
          defaultMessage: "Time zone",
          id: "generic.label.time-zone",
        })}
        passive={passive}
        items={timeZones ?? []}
        value={timeZones?.find((timeZone) => timeZone.ID === value)}
        setValue={(timeZone) => {
          setValue(timeZone?.ID);
        }}
        required={required}
        controlledInputValue={inputValue}
        onInputChange={setInputValue}
        isLoading={isLoading}
        IDKey="ID"
        LabelKey="ID"
        error={error}
        helperText={helperText}
      />
      <input type="hidden" name={name} value={value ?? ""} />
    </>
  );
};

export default TimeZoneAutocomplete;
