import { CoreManagement } from "@springtree/eva-services-core-management";

import { listPriceListsQuery } from "models/price-lists";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";

import useGetPriceListById from "../hooks/use-get-price-list-by-id";

export const generatePriceListAutocomplete = ({
  frontendFilter,
}: {
  frontendFilter?: (item: { ID: number; Name: string }) => boolean;
}) =>
  AutocompleteGenerator<
    CoreManagement.ListPriceLists,
    {
      ID: number;
      Name: string;
    },
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((priceList) => ({
        ID: priceList.ID,
        Name: priceList.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.price-list",
      defaultMessage: "Price list",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listPriceListsQuery,
        initialRequest: { PageConfig: { Limit: 10 } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          PageConfig: {
            ...prev?.PageConfig,
            Filter: { ...prev?.PageConfig?.Filter, Name: newValue },
          },
        }),
      }),
    useItemByID: (id) => {
      const { isLoading, priceList } = useGetPriceListById(id);

      return {
        data: priceList,
        isLoading,
      };
    },
    frontendFilter,
  });
export const {
  MultiAutocomplete: PriceListMultiAutocomplete,
  MultiIDAutocomplete: PriceListMultiIDAutocomplete,
  SingleAutocomplete: PriceListSingleAutocomplete,
  SingleIDAutocomplete: PriceListSingleIDAutocomplete,
} = generatePriceListAutocomplete({});
