import { useIntl } from "react-intl";

import { useFormikContext } from "formik";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { Button } from "@new-black/lyra";

import { singleCustomFieldValuesDirty, singleCustomFieldValuesSubmitting } from "./state";

interface CustomFieldActionProps {
  ID?: number;
  messages?: {
    updateButtonMessage?: string;
    createButtonMessage?: string;
  };
}

const CreateOrUpdateCustomFieldAction = ({ ID, messages }: CustomFieldActionProps) => {
  const intl = useIntl();
  const { dirty, handleSubmit } = useFormikContext();

  const isLoading = useRecoilValue(singleCustomFieldValuesSubmitting(ID));
  const resetCustomFieldChanged = useResetRecoilState(singleCustomFieldValuesDirty(ID));

  return (
    <Button
      isDisabled={!dirty || isLoading}
      isLoading={isLoading}
      variant="primary"
      onPress={() => {
        resetCustomFieldChanged();
        handleSubmit();
      }}
    >
      {ID
        ? messages?.updateButtonMessage ??
          intl.formatMessage({ id: "generic.label.update", defaultMessage: "Update" })
        : messages?.createButtonMessage ??
          intl.formatMessage({ id: "generic.label.create", defaultMessage: "Create" })}
    </Button>
  );
};

export default CreateOrUpdateCustomFieldAction;
