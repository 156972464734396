import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { omitBy } from "lodash";

import { searchProductsQuery } from "models/products";

import { searchProductsRequestReducer } from "./search-products-request-reducer";
import { IProductSearchModalResultBase } from "./types";

const DEFAULT_INCLUDED_FIELDS = [
  "backend_id",
  "custom_id",
  "product_id",
  "product_name",
  "primary_image.blob",
  "display_value",
];

const useProductSearchModalProductResults = <TProduct extends IProductSearchModalResultBase>({
  includedFields,
}: {
  includedFields?: string[];
}) => {
  const [request, dispatch] = useReducer(searchProductsRequestReducer, {});

  // omit filters with empty values to make sure we avoid unnecessary requests when a property is added without any filter values and specify included fields
  const mappedRequest = useMemo(
    () => ({
      ...request,
      Filters: omitBy(request.Filters, (filter) => !filter.Values?.length),
      IncludedFields: Array.from(new Set([...(includedFields ?? []), ...DEFAULT_INCLUDED_FIELDS])),
    }),
    [request, includedFields],
  );

  // make sure we don't call the service unless the user set a query or a filter
  const enableRequest = useMemo(
    () =>
      !!mappedRequest.Query ||
      Object.values(mappedRequest.Filters ?? {}).some((filter) => filter.Values?.length),
    [mappedRequest],
  );

  const { data: products, isFetching: isProductResultsLoading } = useQuery({
    ...searchProductsQuery(mappedRequest),
    enabled: enableRequest,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    keepPreviousData: !!enableRequest,
  });

  const [productResults, setProductResults] = useState<TProduct[]>([]);

  useEffect(() => {
    setProductResults(products?.Products ?? []);
  }, [products?.Products]);

  const total = useMemo(() => products?.Total ?? 0, [products?.Total]);

  const setPropertyFilter = useCallback(
    (property: string, values: string[]) =>
      dispatch({ type: "setPropertyFilter", property, values }),
    [],
  );

  const removePropertyFilter = useCallback(
    (property: string) => dispatch({ type: "removePropertyFilter", property }),
    [],
  );
  const setQuerySearchValue = useCallback(
    (query?: string) => dispatch({ type: "setQuery", query: query || undefined }),
    [],
  );
  const incrementLimit = useCallback(() => dispatch({ type: "incrementLimit" }), []);

  return {
    request,
    productResults,
    setProductResults,
    isProductResultsLoading,
    total,
    setPropertyFilter,
    removePropertyFilter,
    setQuerySearchValue,
    incrementLimit,
  };
};

export default useProductSearchModalProductResults;
