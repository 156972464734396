import { Core } from "@springtree/eva-services-core";

import { getCycleCountOriginTypesQuery } from "models/zoned-cycle-counts";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";

export const {
  MultiAutocomplete: CycleCountOriginTypesMultiAutocomplete,
  SingleAutocomplete: CycleCountOriginTypesAutocomplete,
} = AutocompleteGenerator<Core.GetCycleCountOriginTypes, EVA.Core.EnumDto>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (resp) =>
    resp?.Result?.map((cycleCountOrigin) => ({
      ID: cycleCountOrigin.ID,
      Name: cycleCountOrigin.Name,
      Description: cycleCountOrigin.Description,
    })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.origin-type",
    defaultMessage: "Origin type",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: getCycleCountOriginTypesQuery,
      initialRequest: {},
    }),
});
