import { useEffect, useMemo, useState } from "react";

import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import {
  CancelledError,
  QueryClient,
  QueryFunction,
  QueryKey,
  useQuery,
} from "@tanstack/react-query";
import { isEqual, omit } from "lodash";
import { RecoilState, useRecoilState } from "recoil";

import { LoaderServiceType } from "types/query.types";
import { removeUndefinedValues } from "util/helper";
import { createEVAService } from "util/http";

export interface IQuery<SVC extends IEvaServiceDefinition> {
  queryFn: QueryFunction<SVC["response"], QueryKey>;
  queryKey: QueryKey;
}

export type ServiceQueryFunction<SVC extends IEvaServiceDefinition> = (
  request: SVC["request"],
  key?: QueryKey,
  ouId?: number,
  disableErrorNotification?: ((serviceError?: Error) => boolean) | boolean,
  /**
   * Custom headers to be added to the request. This argument overwrites the headers
   * provided when creating the service query.
   */
  headers?: IEvaServiceCallOptions["headers"],
) => IQuery<SVC>;

type QueryOptions = {
  staleTime?: number;
  cacheTime?: number;
  denyEmptyRequest?: boolean;
  ouId?: number;
  disableErrorNotification?: ((serviceError?: Error) => boolean) | boolean;
};

export const createLoaderQueryService =
  <SVC extends IEvaServiceDefinition>(
    queryClient: QueryClient,
    query: ServiceQueryFunction<SVC>,
    request: SVC["request"],
    key?: QueryKey,
    options?: QueryOptions,
    headers?: IEvaServiceCallOptions["headers"],
  ) =>
  async () => {
    const valueFromCache = queryClient.getQueryData(
      query(request, key, options?.ouId, options?.disableErrorNotification).queryKey,
    ) as SVC["response"] | undefined;

    const value =
      options?.denyEmptyRequest && !request
        ? undefined
        : valueFromCache ??
          (await queryClient
            .fetchQuery({
              ...query(request, key, options?.ouId, options?.disableErrorNotification, headers),
              staleTime: options?.staleTime,
              cacheTime: options?.cacheTime,
            })
            .catch((error) => {
              if (error instanceof CancelledError && error.silent) {
                // React query cancelled a query. Ignore the error in this situation
                return undefined;
              }
              throw error;
            }));

    const queryValue: LoaderServiceType<SVC["request"], SVC["response"]> = {
      value,
      request: request!,
      queryProps: {
        loaderKey: key,
        initialData: value,
        refetchOnMount: !!valueFromCache,
        staleTime: options?.staleTime,
        cacheTime: options?.cacheTime,
      },
    };

    return queryValue;
  };

const DEFAULTKEY = ["default"];

export const createServiceQuery = <SVC extends IEvaServiceDefinition>(
  service: new () => SVC,
  denyEmptyRequest?: boolean,
  throwOnError?: boolean,
  keepPreviousData?: boolean,
  serviceOptions?: IEvaServiceCallOptions,
) => {
  const serviceQueryKeys = {
    base: [service?.name] as const,
    withKey: (key?: QueryKey) => {
      const newKey = key ?? DEFAULTKEY;
      return [...serviceQueryKeys.base, ...newKey] as const;
    },
    withRequest: (request: SVC["request"], key?: QueryKey) => {
      const newKey = key ?? DEFAULTKEY;
      return [...serviceQueryKeys.withKey(newKey), request] as const;
    },
  };

  const evaService = createEVAService(service);

  const serviceQuery: ServiceQueryFunction<SVC> = (
    request,
    key,
    _,
    disableErrorNotification,
    headers,
  ) => ({
    queryKey: serviceQueryKeys.withRequest(request, key),
    queryFn: () =>
      evaService.call(
        request,
        { ...serviceOptions, headers },
        throwOnError,
        disableErrorNotification,
      ),
  });

  const serviceQueryWithOU: ServiceQueryFunction<SVC> = (
    request,
    key,
    ouId,
    disableErrorNotification,
    headers,
  ) => ({
    queryKey: serviceQueryKeys.withRequest(request, key),
    queryFn: () =>
      evaService.call(
        request,
        { ...serviceOptions, requestedOrganizationUnitID: ouId, headers },
        throwOnError,
        disableErrorNotification,
      ),
  });

  const serviceLoaderQuery = (
    queryClient: QueryClient,
    request: SVC["request"],
    key?: QueryKey,
    options?: QueryOptions,
    headers?: IEvaServiceCallOptions["headers"],
  ) => {
    return createLoaderQueryService(
      queryClient,
      options?.ouId ? serviceQueryWithOU : serviceQuery,
      request,
      key,
      { ...options, denyEmptyRequest },
      headers,
    );
  };

  const useServiceQueryHook = (
    request: SVC["request"],
    queryProps: LoaderServiceType<SVC["request"], SVC["response"]>["queryProps"],
  ) => {
    const queryArgs = useMemo(
      () => ({
        ...(queryProps.ouId
          ? serviceQueryWithOU(
              request,
              queryProps.loaderKey,
              queryProps.ouId,
              queryProps.disableErrorNotification,
              queryProps.headers,
            )
          : serviceQuery(
              request,
              queryProps.loaderKey,
              undefined,
              queryProps.disableErrorNotification,
              queryProps.headers,
            )),
        ...omit(queryProps, "ouId"),
        keepPreviousData: keepPreviousData ?? true,
        refetchOnWindowFocus: queryProps?.refetchOnWindowFocus ?? false,
        enabled: denyEmptyRequest || queryProps.denyEmptyRequest ? !!request : true,
      }),
      [queryProps, request],
    );
    return useQuery(queryArgs);
  };

  const useServiceQueryWithRequest = (
    initialRequest: SVC["request"],
    queryProps: LoaderServiceType<SVC["request"], SVC["response"]>["queryProps"],
    listenToUpdates?: boolean,
  ) => {
    const [request, setRequest] = useState(initialRequest);
    useEffect(() => {
      if (listenToUpdates) {
        setRequest(initialRequest);
      }
    }, [initialRequest, listenToUpdates]);
    const queryResult = useServiceQueryHook(request, {
      ...queryProps,
      initialData: isEqual(removeUndefinedValues(request), removeUndefinedValues(initialRequest))
        ? queryProps.initialData
        : undefined,
    });
    return { request, setRequest, queryResult };
  };

  const useServiceQueryWithRecoilRequest = (
    initialRequest: SVC["request"] | undefined,
    recoilState: RecoilState<SVC["request"]>,
    queryProps: LoaderServiceType<SVC["request"], SVC["response"]>["queryProps"],
  ) => {
    const [request, setRequest] = useRecoilState(recoilState);

    useEffect(() => {
      if (!request && initialRequest) {
        setRequest(initialRequest);
      }
    }, [initialRequest, request, setRequest]);

    const queryResult = useServiceQueryHook(request, queryProps);
    return { request, setRequest, queryResult };
  };

  return {
    serviceQuery,
    serviceQueryKeys,
    serviceQueryWithOU,
    serviceLoaderQuery,
    useServiceQueryHook,
    useServiceQueryWithRequest,
    useServiceQueryWithRecoilRequest,
  };
};
