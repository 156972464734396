import { useIntl } from "react-intl";

import { Field, FieldProps } from "formik";

import Input from "components/suite-ui/input";

interface IInstructionFieldProps {
  passive?: boolean;
}

const InstructionField = ({ passive }: IInstructionFieldProps) => {
  const intl = useIntl();
  return (
    <Field name="Name">
      {({ field, meta }: FieldProps) => (
        <Input
          label={intl.formatMessage({
            id: "custom-field.input.instruction.label",
            defaultMessage: "Instruction / Name",
          })}
          required
          passive={passive}
          {...field}
          value={field.value ?? ""}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : undefined}
        />
      )}
    </Field>
  );
};

export default InstructionField;
