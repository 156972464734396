import { useMemo } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";

const useGetApiUrl = () => {
  const appConfig = hooks.useGetServiceResponse(
    state.currentUser.currentUserApplicationConfigurationState,
  );
  const assetsUrl = useMemo<string | undefined>(
    () => appConfig?.Configuration?.["Urls:Api"] as string | undefined,
    [appConfig],
  );

  return assetsUrl;
};

export default useGetApiUrl;
