import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

export const getPriceListByIdServiceState = builders.buildServiceFamilyState({
  service: CoreManagement.GetPriceListByID,
  key: "Composite:CoreManagement:GetPriceListByID",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.ID) {
      return req;
    }

    return { ...req, ID: parameter };
  },
});

export const getPriceListByIdResultSelector = selectorFamily({
  key: "Composite:CoreManagement:GetPriceListByID:Result",
  get:
    (ID: number | undefined) =>
    ({ get }) =>
      get(getPriceListByIdServiceState.response(ID)),
});
