import { useMemo } from "react";
import { useIntl } from "react-intl";

import * as Yup from "yup";

const useCustomFieldValidation = () => {
  const intl = useIntl();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        Name: Yup.string().required(
          intl.formatMessage({
            id: "validation.required",
            defaultMessage: "This field is required",
          }),
        ),
        DataType: Yup.number().required(
          intl.formatMessage({
            id: "validation.required",
            defaultMessage: "This field is required",
          }),
        ),
        BackendID: Yup.string(),
        IsArray: Yup.boolean(),
        EnumValues: Yup.object(),
      }),
    [intl],
  );

  return validationSchema;
};

export default useCustomFieldValidation;
