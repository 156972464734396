import { useCallback, useId, useRef } from "react";
import { createPortal } from "react-dom";

import { CircularProgress } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { cva } from "class-variance-authority";
import classnames from "classnames";

import { SearchListFieldClearIcon } from "./helpers/search-list-field-clear-icon";
import { SearchListFieldHelperText, SearchListFieldLabel } from "./helpers";
import { SearchListFieldMultipleSelectionCombobox } from "./search-list-field-multiple-selection-combobox";
import { SearchListFieldPortalWrapper } from "./search-list-field-portal-wrapper";
import { SearchListFieldSingleSelectionCombobox } from "./search-list-field-single-selection-combobox";
import {
  ISearchListFieldBaseProps,
  ISearchListFieldControlledMultipleSelectionProps,
  ISearchListFieldControlledSingleSelectionProps,
  ISearchListFieldOptionsProps,
  TSearchListFieldKeyType,
} from "./types";
import { useSearchListFieldDisplayValue } from "./use-search-list-field-display-value";
import { useSearchListFieldPopoverHandlers } from "./use-search-list-field-popover-handlers";
import { useSearchListFieldPopoverPositioning } from "./use-search-list-field-popover-positioning";

export const buttonBorderStyles = cva(
  [
    "absolute bottom-0 w-full border border-x-0 border-t-0 border-b-[color:#666666] transition-all group-focus-within:border-b-2",
  ],
  {
    variants: {
      isPopoverOpen: {
        true: "border-b-2",
      },
      disabled: {
        true: "border-dotted",
        false: "border-solid group-hover:border-b-2 group-hover:border-b-[color:#000]",
      },
      error: {
        true: "",
      },
      warning: {
        true: "",
      },
    },
    compoundVariants: [
      {
        error: true,
        warning: false,
        class: "border-b-error",
      },
      {
        error: true,
        warning: true,
        class: "border-b-error",
      },
      {
        error: false,
        warning: true,
        class: "border-b-[color:var(--color-warning-3)]",
      },
      {
        error: false,
        warning: false,
        isPopoverOpen: true,
        class: "border-b-[color:rgb(0,122,255)]",
      },
      {
        error: false,
        warning: false,
        class: "group-focus-within:!border-b-[color:rgb(0,122,255)]",
      },
    ],
  },
);

export const SearchListFieldControlled = <T,>({
  autoFocus,
  classNames,
  disabled,
  disablePopoverPortal,
  endAdornment,
  error,
  filterOptions,
  getSecondaryLabel,
  helperText,
  idKey,
  isLoading,
  isOptionDisabled,
  items,
  label,
  labelKey,
  multiple,
  onLoadMore,
  onPopoverClose,
  onPopoverOpen,
  preventPopoverCloseOnChange,
  query,
  renderValue,
  required,
  setQuery,
  setValue,
  setValues,
  showClearIcon = true,
  showOptionEndAdornment,
  small,
  value,
  values,
  warning,
}: ISearchListFieldOptionsProps<T, TSearchListFieldKeyType<T>, TSearchListFieldKeyType<T>> &
  ISearchListFieldBaseProps<T> &
  (
    | ISearchListFieldControlledSingleSelectionProps<T>
    | ISearchListFieldControlledMultipleSelectionProps<T>
  )) => {
  const id = useId();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const buttonContentRef = useRef<HTMLDivElement | null>(null);
  const displayValueWrapperRef = useRef<HTMLSpanElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>(null);
  const inPlaceContainerRef = useRef<HTMLDivElement | null>(null);

  const styles = useSearchListFieldPopoverPositioning(buttonRef);

  const { closePopover, isPopoverOpen, openPopover } = useSearchListFieldPopoverHandlers(
    buttonRef,
    portalRef,
    onPopoverOpen,
    onPopoverClose,
  );

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
      if (disabled) {
        return;
      }

      if (
        event.target === buttonRef.current ||
        event.target === buttonContentRef.current ||
        event.target === displayValueWrapperRef.current
      ) {
        openPopover();
      }
    },
    [disabled, openPopover],
  );

  const onChangeHandler = useCallback(
    (newValue: T | T[] | undefined) => {
      // On multiple selection, prevent popover close on change by default
      const localPreventPopoverCloseOnChange = multiple
        ? preventPopoverCloseOnChange ?? true
        : preventPopoverCloseOnChange;

      if (multiple) {
        setValues?.(newValue as T[] | undefined);
      } else {
        setValue?.(newValue as T | undefined);
      }

      if (!localPreventPopoverCloseOnChange) {
        closePopover("button");
      }
    },
    [closePopover, multiple, preventPopoverCloseOnChange, setValue, setValues],
  );

  const onClear = useCallback(
    (
      event?: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.KeyboardEvent<HTMLSpanElement>,
    ) => {
      // Prevent reopening the popover
      event?.stopPropagation();
      event?.preventDefault();

      onChangeHandler?.(undefined);

      // Move focus to button
      buttonRef.current?.focus();
    },
    [onChangeHandler],
  );

  const onDeleteItem = useCallback(
    (item?: T) => {
      if (multiple) {
        const newValue = values?.filter((el) => el?.[idKey] !== item?.[idKey]);

        setValues?.(newValue);
      }
    },
    [idKey, multiple, setValues, values],
  );

  const displayValue = useSearchListFieldDisplayValue<T, keyof T, keyof T>(
    idKey,
    labelKey,
    onDeleteItem,
    buttonRef,
    multiple,
    value,
    values,
    disabled,
    renderValue,
  );

  return (
    <div>
      <div className={classnames("group relative w-full", disabled && "opacity-[63%]")}>
        <button
          type="button"
          disabled={disabled}
          ref={buttonRef}
          id={`button-${id}`}
          className={classnames(
            "relative mt-3 w-full border-none bg-transparent pl-4 text-left transition-all",
            small ? "min-h-[40px] pb-0.5 pt-1" : "min-h-[50px] py-2 pr-2",
            classNames?.field,
          )}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "Space") {
              openPopover();

              // Prevent default to avoid propagating the space and enter actions to search input from popover (which is auto-focused)
              e.preventDefault();
            }

            // Reset value on escape and backspace if the current focused element is the button itself
            if (
              (e.code === "Escape" || e.code === "Backspace") &&
              document.activeElement === buttonRef.current
            ) {
              if (value !== undefined || values?.length) {
                // Prevent propagation of escape and backspace if a value is present. This restricts the keyboard events in question only
                // to clearing the value. For example, if the search list field is used within a modal, then you would first want to clear
                // the value and thereafter close the modal.
                e.stopPropagation();
              }

              if (multiple) {
                setValues?.(undefined);
              } else {
                setValue?.(undefined);
              }
            }
          }}
          autoFocus={autoFocus}
          aria-haspopup="true"
          aria-expanded={isPopoverOpen}
          aria-controls={id}
          onClickCapture={onClick}
          aria-valuetext={
            multiple
              ? `${values?.map((item) => item?.[labelKey] ?? "").join(", ") ?? ""}`
              : `${value?.[labelKey] ?? ""}`
          }
        >
          <div
            className="flex flex-nowrap items-center justify-between gap-5 transition-all"
            ref={buttonContentRef}
          >
            {label ? (
              <SearchListFieldLabel
                hasValue={multiple ? !!values?.length : !!value}
                isPopoverOpen={isPopoverOpen}
                disabled={disabled}
                error={error}
                warning={warning}
                label={label}
                required={required}
                small={small}
                htmlFor={`button-${id}`}
                onClick={openPopover}
              />
            ) : null}

            {displayValue ? (
              <span
                className={classnames(
                  "text-legacy-base flex flex-wrap items-center",
                  small ? "gap-0.5" : "gap-1",
                )}
                ref={displayValueWrapperRef}
              >
                {displayValue}
              </span>
            ) : null}

            <span className="flex items-center">
              {isLoading?.initialValue ? (
                <CircularProgress
                  className="mr-1.5"
                  color="secondary"
                  size={20}
                  onClick={openPopover}
                />
              ) : null}

              <SearchListFieldClearIcon
                show={!(disabled || (!value && !values?.length)) && showClearIcon}
                disabled={disabled}
                onClear={onClear}
              />

              {endAdornment}

              <ArrowDropDown
                className={classnames("text-secondary", isPopoverOpen ? "rotate-180" : undefined)}
                onClick={openPopover}
              />
            </span>
          </div>
        </button>

        <div
          className={classnames(
            classNames?.border,
            buttonBorderStyles({
              disabled: !!disabled,
              error: !!error,
              warning: !!warning,
              isPopoverOpen,
            }),
          )}
        />
      </div>

      {helperText ? (
        <SearchListFieldHelperText helperText={helperText} error={error} warning={warning} />
      ) : null}

      {disablePopoverPortal ? (
        // Give it `absolute` class so it is excluded from flex / grid calculations
        <div className="absolute" ref={inPlaceContainerRef} />
      ) : null}

      {isPopoverOpen
        ? createPortal(
            <SearchListFieldPortalWrapper>
              <div className="fixed inset-0 z-[99999]">
                <div
                  id={id}
                  ref={portalRef}
                  style={styles?.wrapper}
                  className={classnames(
                    "rounded bg-surface-primary",
                    "shadow-[0px_0px_4px_0px_rgba(0,_0,_0,_0.12),_0px_1px_1px_1px_rgba(0,_0,_0,_0.10),_0px_4px_4px_0px_rgba(0,_0,_0,_0.10)]",
                  )}
                  tabIndex={-1}
                  role="dialog"
                  aria-modal={true}
                >
                  {multiple ? (
                    <SearchListFieldMultipleSelectionCombobox
                      values={values}
                      setValues={onChangeHandler}
                      items={items}
                      idKey={idKey}
                      labelKey={labelKey}
                      getSecondaryLabel={getSecondaryLabel}
                      query={query}
                      setQuery={setQuery}
                      isLoading={isLoading?.options}
                      styles={styles.options}
                      isOptionDisabled={isOptionDisabled}
                      onLoadMore={onLoadMore}
                      autoFocus
                      filterOptions={filterOptions}
                      showOptionEndAdornment={showOptionEndAdornment}
                    />
                  ) : (
                    <SearchListFieldSingleSelectionCombobox
                      value={value}
                      setValue={onChangeHandler}
                      items={items}
                      idKey={idKey}
                      labelKey={labelKey}
                      getSecondaryLabel={getSecondaryLabel}
                      query={query}
                      setQuery={setQuery}
                      isLoading={isLoading?.options}
                      styles={styles.options}
                      isOptionDisabled={isOptionDisabled}
                      onLoadMore={onLoadMore}
                      autoFocus
                      filterOptions={filterOptions}
                      showOptionEndAdornment={showOptionEndAdornment}
                    />
                  )}
                </div>
              </div>
            </SearchListFieldPortalWrapper>,
            disablePopoverPortal && inPlaceContainerRef.current
              ? inPlaceContainerRef.current
              : document.body,
          )
        : null}
    </div>
  );
};
