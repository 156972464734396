import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useHasFunctionality } from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { IChapter } from "../chapter-finder.types";

export const useTasksChapters = () => {
  const intl = useIntl();

  const { functionalityAllowed: customUserTasksAllowed } = useHasFunctionality(
    Functionalities.CUSTOM_USER_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: printTasksAllowed } = useHasFunctionality(
    Functionalities.PRINT_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: zonedCycleCountsSchedulesAllowed } = useHasFunctionality(
    Functionalities.ZONED_CYCLE_COUNTS_SCHEDULE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: cycleCountsZoneGroupsAllowed } = useHasFunctionality(
    Functionalities.CYCLE_COUNT_ZONE_GROUPS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: zonedCycleCountAllowed } = useHasFunctionality(
    Functionalities.ZONED_CYCLE_COUNTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrganizationUnitSets } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewRepairTasksAllowed } = useHasFunctionality(
    Functionalities.REPAIRS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: fullStockCountsAllowed } = useHasFunctionality(
    Functionalities.FULL_STOCK_COUNT,
    FunctionalityScope.View,
  );

  const tasksChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (customUserTasksAllowed) {
      chapters.push(
        {
          title: intl.formatMessage({
            id: "module.tasks.chapters.ship-from-store",
            defaultMessage: "Ship from store",
          }),
          path: "/ship-from-store",
        },
        {
          title: intl.formatMessage({
            id: "module.tasks.chapters.incoming-shipment",
            defaultMessage: "Incoming shipment",
          }),
          path: "/incoming-shipment",
        },
        {
          title: intl.formatMessage({
            id: "module.tasks.chapters.reservation-cleanup",
            defaultMessage: "Reservations cleanup",
          }),
          path: "/reservation-cleanup",
        },
        {
          title: intl.formatMessage({
            id: "module.tasks.chapters.reservations",
            defaultMessage: "Reservations",
          }),
          path: "/reservations",
        },
      );
    }

    if (customUserTasksAllowed && viewRepairTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.repairs",
          defaultMessage: "Repairs",
        }),
        path: "/repairs",
      });
    }

    if (printTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.tasks.chapters.print",
          defaultMessage: "Print",
        }),
        path: "/print",
      });
    }

    //TODO: check functionality
    if (
      cycleCountsZoneGroupsAllowed ||
      (viewOrganizationUnitSets && cycleCountsZoneGroupsAllowed)
    ) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.zones-and-zone-groups",
          defaultMessage: "Zones and zone groups",
        }),
        path: "/zones-and-zone-groups",
      });
    }

    if (zonedCycleCountsSchedulesAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.tasks.chapters.zoned-cycle-counts-scheduled",
          defaultMessage: "Zoned cycle counts scheduled",
        }),
        path: "/zoned-cycle-counts-scheduled",
      });
    }

    if (zonedCycleCountAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.tasks.chapters.zoned-cycle-counts",
          defaultMessage: "Zoned cycle counts",
        }),
        path: "/zoned-cycle-counts",
      });
    }

    if (fullStockCountsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.stock.chapters.full-stock-counts",
          defaultMessage: "Full stock count",
        }),
        path: "/full-stock-counts",
      });
    }

    return chapters;
  }, [
    customUserTasksAllowed,
    cycleCountsZoneGroupsAllowed,
    fullStockCountsAllowed,
    intl,
    printTasksAllowed,
    viewOrganizationUnitSets,
    viewRepairTasksAllowed,
    zonedCycleCountAllowed,
    zonedCycleCountsSchedulesAllowed,
  ]);

  return tasksChapters;
};
