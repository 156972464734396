import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, SearchField, SvgIcon, TagInput } from "@new-black/lyra";

import useDebounce from "hooks/suite-react-hooks/use-debounce";

import { useProductSearchModalContext } from "../product-search-modal-context";

export default function ProductSearchModalFilters() {
  const intl = useIntl();
  const { filters, removePropertyFilter, setPropertyFilter } = useProductSearchModalContext();

  return (
    <div className="max-h-[15.25rem] space-y-4 overflow-y-auto pb-4">
      <div className="mx-4">
        <SearchInput />
      </div>

      {filters.map((filter) => (
        <div className="mx-4 flex items-end gap-4" key={filter.ProductProperty}>
          <TagInput
            label={intl.formatMessage(
              {
                id: "product-search-property.input.label",
                defaultMessage: "Property: {name}",
              },
              { name: filter.ProductProperty },
            )}
            hideHintLabel
            value={filter.Values ?? []}
            onChange={(values) => setPropertyFilter(filter.ProductProperty, values)}
            fullWidth
          />
          <Button
            variant="icon"
            onPress={() => removePropertyFilter(filter.ProductProperty)}
            className="mb-[1.0625rem]"
            tooltip={intl.formatMessage({
              id: "generic.label.delete",
              defaultMessage: "Delete",
            })}
          >
            <SvgIcon name="delete" />
          </Button>
        </div>
      ))}
    </div>
  );
}

function SearchInput() {
  const intl = useIntl();
  const { querySearchValue, setQuerySearchValue } = useProductSearchModalContext();

  const [internalValue, setInternalValue] = useState(querySearchValue);
  const debouncedValue = useDebounce(internalValue, 500);

  useEffect(() => {
    setQuerySearchValue(debouncedValue);
  }, [debouncedValue, setQuerySearchValue]);

  useEffect(() => {
    setInternalValue(querySearchValue);
  }, [querySearchValue]);

  return (
    <SearchField
      value={internalValue || ""}
      onChange={(value) => {
        setInternalValue(value);
      }}
      label={intl.formatMessage({
        id: "generic.label.search-query",
        defaultMessage: "Search query",
      })}
      hideInputFooter
      hideHintLabel
      onClear={() => {
        setInternalValue(undefined);
      }}
    />
  );
}
