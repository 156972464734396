import { ReactNode } from "react";

import { Table, TableProps } from "@new-black/lyra";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";

import useListTableSettings from "./use-list-table-settings";

export interface IProductFilterList {
  "aria-label"?: TableProps<IEVAFilterArrayItem>["aria-label"];
  options?: TableProps<IEVAFilterArrayItem>["options"];
  customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode;
}

/**
 * Component that shows the list of filters that are set within the product filter.
```
 */
export default function ProductFilterList(props: IProductFilterList) {
  const listTableConfig = useListTableSettings(props);

  return <Table {...listTableConfig} />;
}
