import { useCallback } from "react";
import { useIntl } from "react-intl";

import { useFormikContext } from "formik";

import Text from "components/suite-ui/text";

import { StyledListItemIcon, StyledMenuItem, StyledSelectInput } from "../styled-components";
import { DataTypes, ICustomFieldValues } from "../types";

import {
  useDatatypeResolvers,
  useDataTypeSelectorMenuItems,
} from "./hooks/use-data-type-selectors";

interface IDataTypeSelector {
  passive?: boolean;
}
const DataTypeSelector = ({ passive }: IDataTypeSelector) => {
  const intl = useIntl();
  const {
    setFieldValue,
    values: { DataType, ID, IsArray: isArray },
  } = useFormikContext<ICustomFieldValues>();

  const { boolOrMultiOptionMenuItems, dateMenuItems, lineOrNumberMenuItems } =
    useDataTypeSelectorMenuItems();

  const { resolveDataTypeToMenuItem, resolveMenuItemToDataType } = useDatatypeResolvers(isArray);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.value as DataTypes;

      switch (newVal) {
        case "line":
        case "multiline":
        case "decimal":
        case "toggle":
        case "date":
        case "datetime":
          setFieldValue("DataType", resolveMenuItemToDataType(newVal));
          setFieldValue("IsArray", undefined);
          break;
        case "number":
          setFieldValue("DataType", resolveMenuItemToDataType(newVal));
          setFieldValue("IsArray", undefined);
          break;
        case "radiobuttons":
          setFieldValue("DataType", resolveMenuItemToDataType(newVal));
          setFieldValue("IsArray", false);
          break;
        case "checkboxes":
          setFieldValue("DataType", resolveMenuItemToDataType(newVal));
          setFieldValue("IsArray", true);
          break;
        default: {
          setFieldValue("DataType", 0);
          setFieldValue("IsArray", undefined);
        }
      }
    },
    [resolveMenuItemToDataType, setFieldValue],
  );

  return (
    <StyledSelectInput
      select
      passive={passive}
      disabled={!!ID}
      value={resolveDataTypeToMenuItem(DataType)}
      onChange={handleChange}
      label={intl.formatMessage({
        id: "custom-field.input.data-type.label",
        defaultMessage: "Field type",
      })}
    >
      {lineOrNumberMenuItems.map(({ icon, label, value }, index) => (
        <StyledMenuItem
          key={value}
          value={value}
          divider={index === lineOrNumberMenuItems.length - 1}
        >
          <StyledListItemIcon>{icon}</StyledListItemIcon>
          <Text>{label}</Text>
        </StyledMenuItem>
      ))}
      {boolOrMultiOptionMenuItems.map(({ icon, label, value }, index) => (
        <StyledMenuItem
          key={value}
          value={value}
          divider={index === boolOrMultiOptionMenuItems.length - 1}
        >
          <StyledListItemIcon>{icon}</StyledListItemIcon>
          <Text>{label}</Text>
        </StyledMenuItem>
      ))}
      {dateMenuItems.map(({ icon, label, value }) => (
        <StyledMenuItem key={value} value={value}>
          <StyledListItemIcon>{icon}</StyledListItemIcon>
          <Text>{label}</Text>
        </StyledMenuItem>
      ))}
    </StyledSelectInput>
  );
};

export default DataTypeSelector;
